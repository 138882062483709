import React, { useContext } from "react";
import { logoNVIGOnly } from "../../assets/assetsExport";
import { useSelector } from "react-redux";
import { getLang } from "../../features/app/appSlice";
import { IconRight } from "../../assets/svgExport";
import { NavLink } from "react-router-dom";

export default function ContactUsBanner() {
  const lang = useSelector(getLang);

  return (
    <div className="relative bg-main px-10 sm:px-16 py-10 sm:py-16 h-[320px]">
      <div>
        <p className="font-poster text-5xl border-b border-black pb-2 sm:pb-4 mb-4 sm:mb-8 inline-flex flex-col gap-2 md-lg:gap-0 md-lg:flex-row">
          {lang ? (
            <>
              <span>Get in Touch&nbsp;</span>
              <span>with Our Team</span>
            </>
          ) : (
            <span className="text-3xl">与我们的团队联系</span>
          )}
        </p>
        <NavLink to="/contact" className="flex gap-2 items-center">
          <div className="inline-block bg-white rounded-full p-1">
            <IconRight width={16} height={16} />
          </div>
          <span className="text-white font-medium text-lg">
            {lang ? "Contact Us" : "联系我们"}
          </span>
        </NavLink>
      </div>
      <img
        className="absolute w-[170px] sm:w-[250px] right-0 bottom-0"
        src={logoNVIGOnly}
        alt={lang ? "NVIG's Logo Zoomed in" : "NVIG的标志放大"}
      />
    </div>
  );
}
