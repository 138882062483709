import React, { useContext } from "react";
import Publication from "../../components/Publication/Publication";
import TitleDescriptionItem from "../../components/TitleDescriptionItem/TitleDescriptionItem";
import {
  imgAboutBg,
  imgOurFirmBg,
  logoArla,
  logoCiol,
  logoOisc,
} from "../../assets/assetsExport";
import { useSelector } from "react-redux";
import { getLang } from "../../features/app/appSlice";
import ContactUsBanner from "../../components/ContactUsBanner/ContactUsBanner";
import SlideUpAnimation from "../../components/SlideUpAnimation/SlideUpAnimation";

export default function AboutUsPage() {
  const lang = useSelector(getLang);
  function HeroSection() {
    return (
      <div className="h-[400px] w-screen">
        <div className="">
          <img
            className="absolute top-0 left-0 w-screen h-[400px] object-cover"
            src={imgAboutBg}
            alt="Mayfair, London. Decoration background."
          />
          <div className="absolute top-0 left-0 w-full h-[400px] bg-gradient-to-t from-black/80 to-black/50"></div>
        </div>

        <div className="relative content-container z-[99] h-full w-full flex flex-col items-start p-10 sm:p-14 gap-8 text-left justify-end">
          <div>
            <p className="text-white font-light">{lang ? "ABOUT" : "关于"}</p>
            <h1 className="text-white font-poster text-7xl mt-2">
              {lang ? "About Us" : "关于我们"}
            </h1>
          </div>
        </div>
      </div>
    );
  }

  function OurFirmSection() {
    return (
      <div id="our-firm">
        <div className="mb-20">
          <SlideUpAnimation>
            <p className="flex flex-col sm:flex-row gap-4 sm:gap-0 font-poster text-5xl mt-20 mb-10">
              {lang ? (
                <>
                  <span>Global Vision,&nbsp;</span>
                  <span>Local Expertise</span>
                </>
              ) : (
                "国际视野，本地专识"
              )}
            </p>
          </SlideUpAnimation>
          <p>
            {lang
              ? "New Vision International Group, headquartered in London, UK, is a specialized platform for global asset allocation. We offer cross-border comprehensive services for high-net-worth individuals. The group's main business segments include family office, real estate, art and culture, education planning, think tank exchange, private club, and the Cambridge Foundation."
              : "新愿景国际集团总部位于英国伦敦，是一个全球资产配置专业平台，为高净值人士提供跨境综合服务。集团业务主要包括家族办公室、地产置业、艺术文化、教育规划、智库交流、私人俱乐部、剑桥基金会七大板块。"}
          </p>
        </div>
        <div className="header-border-t-flex">
          <h2>{lang ? "Our Firm" : "我们的公司"}</h2>
          <div>
            <p className="mb-10">
              {lang
                ? "Our firm, founded by Cambridge University graduates, is one of the few in the UK and Europe with a deep understanding of the needs of Chinese high-net-worth individuals. The founding members are all Cambridge University alumni, and the core team and experts are leaders in their respective fields, with over ten years of senior management experience and professional expertise. Adhering to the philosophy of 'Global Vision, Local Expertise,' we offer one-stop customized services for high-net-worth individuals and families with comprehensive global development needs, including wealth inheritance, children's education, identity planning, tax planning, and private banking."
                : "新愿景拥有英国乃至欧洲为数不多、深谙中国高净值人群需求的专业团队。集团创始人员均毕业于英国剑桥大学，团队核心人员和专家均为相关行业的领军式人物，在相关领域中拥有十年以上高级管理经验和专业积淀。团队秉承“国际视野，本地专识 Global Vision, Local Expertise”的理念，为有全球化综合发展需求的高净值人群和家庭提供一站式定制服务，包括财富传承、子女教育、身份规划、税务筹划与私人银行等。"}
            </p>
            <img src={imgOurFirmBg} alt="people discussing" />
          </div>
        </div>
      </div>
    );
  }

  function OurPurposeAndValuesSection() {
    const values = [
      {
        title: { en: "Integrity", cn: "诚信" },
        description: {
          en: "We uphold the highest ethical standards, fostering transparency and long-term partnerships.",
          cn: "我们坚持最高道德标准，秉持透明原则，致力于建立长期合作关系。",
        },
      },
      {
        title: { en: "Excellence", cn: "卓越" },
        description: {
          en: "We strive for the highest level of expertise and service, delivering tailored solutions with precision.",
          cn: "我们追求卓越的专业水准与服务品质，以精准定制方案满足客户需求。",
        },
      },
      {
        title: { en: "Innovation", cn: "创新" },
        description: {
          en: "We embrace change and continuously refine our strategies to meet evolving global demands.",
          cn: "我们拥抱变革，不断优化策略，以适应全球不断变化的需求。",
        },
      },
      {
        title: { en: "Client-Centric", cn: "以客户为中心" },
        description: {
          en: "Your success is our priority—we provide personalized, comprehensive solutions to meet your unique needs.",
          cn: "您的成功是我们的首要目标——我们提供个性化、全方位的解决方案，以满足您的独特需求。",
        },
      },
    ];

    return (
      <div id="purposes-values" className="pt-10">
        <h2 className="header-border-t mt-20">
          {lang ? "Our Purpose & Values" : "我们的宗旨与价值观"}
        </h2>
        <p className="header-description">
          {lang
            ? "At NVIG, we are committed to integrity, excellence, and client success. Our purpose is to provide strategic solutions that empower individuals, families, and businesses to thrive in an evolving global landscape."
            : "在NVIG，我们致力于诚信、卓越和客户成功。我们的宗旨是提供战略解决方案，赋能个人、家庭和企业在不断变化的全球环境中蓬勃发展。"}
        </p>
        <div className="content-grid">
          {values.map((i) => {
            return (
              <TitleDescriptionItem
                leftLine={true}
                title={lang ? i.title.en : i.title.cn}
                description={lang ? i.description.en : i.description.cn}
              />
            );
          })}
        </div>
      </div>
    );
  }

  function OurComplianceSection() {
    const compliances = [
      {
        title: {
          en: "Office of the Immigration Services Commissioner",
          cn: "英国内政部移民事务署特许法律机构 OISC",
        },
        description: {
          en: "Provides regulated immigration advice, ensuring clients receive legally compliant and trustworthy professional guidance.",
          cn: "提供受监管的移民咨询，确保客户获得合法合规且值得信赖的专业指导。",
        },
        img: { src: logoOisc, alt: "OISC logo" },
      },
      {
        title: {
          en: "Association of Residential Letting Agents",
          cn: "英国房产监管认证咨询机构 ARLA",
        },
        description: {
          en: "Ensures professional standards in lettings, offering clients legal compliance, transparent transactions, and financial protection.",
          cn: "确保租赁行业的专业标准，为客户提供法律合规、透明交易和资金保障。",
        },
        img: { src: logoArla, alt: "ARLA logo" },
      },
      {
        title: {
          en: "Chartered Institute of Linguists",
          cn: "英国皇家翻译协会认证会员 CIOL",
        },
        description: {
          en: "Assures professional language services, ensuring clients receive accurate and certified translations or interpretations.",
          cn: "确保专业语言服务，为客户提供准确、认证的翻译或口译。",
        },
        img: { src: logoCiol, alt: "CIOL logo" },
      },
    ];

    return (
      <div id="compliance-legality" className="pt-20">
        <h2 className="header-border-t">
          {lang ? "Compliance & Legality" : "合规与合法性"}
        </h2>
        <p className="header-description">
          {lang
            ? "New Vision Group emphasizes the importance of compliance and legality in its business operations and development. It is a professional service organization in the UK with various certifications covering law, real estate, and consulting. All business activities are conducted under the supervision of relevant authoritative bodies in the UK. We aim to safeguard your, your family's, and your business's growth in globalization with integrity and professional standards, ensuring your satisfaction and trust."
            : "新愿景集团注重商业运营和发展的合规与合法性，是在英国拥有各项资质认证的的专业服务机构，其认证资质涵盖法律、地产、咨询等范围，业务均在英国相关权威机构监管下开展。我们期待⽤诚信、专业的职业准则，以您的满意与信任为宗旨，为您、您的家族、您的事业在全球化⻓⾜发展中保驾护航。"}
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-10 gap-y-20 my-10 mb-40">
          {compliances.map((i) => {
            return (
              <TitleDescriptionItem
                img={i.img}
                title={lang ? i.title.en : i.title.cn}
                description={lang ? i.description.en : i.description.cn}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <div>
      <HeroSection />
      <div className="content-container">
        <OurFirmSection />
        <OurPurposeAndValuesSection />
        <Publication />
        <OurComplianceSection />
      </div>
      <ContactUsBanner />
    </div>
  );
}
