import React from "react";

export default function Slogen({
  tag,
  slogen,
  subSlogen,
}: {
  tag: string;
  slogen: any;
  subSlogen?: string;
}) {
  return (
    <div className="bg-secondary-light px-10 py-16 flex flex-col items-center text-center">
      <p className="text-xs font-light">{tag}</p>
      {slogen && (
        <div className="font-header leading-normal text-3xl pt-4">{slogen}</div>
      )}
      {subSlogen && (
        <p className="max-w-[540px] text-sm font-light font-text pt-16">
          {subSlogen}
        </p>
      )}
    </div>
  );
}
