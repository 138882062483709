// src/components/HtmlOverflowManager.tsx
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { getShowContactPopup } from "../../features/app/appSlice";

const HtmlOverflowManager: React.FC = React.memo(() => {
  const showContactPopup = useSelector(getShowContactPopup);

  useEffect(() => {
    const html = document.querySelector("html");
    if (showContactPopup) {
      if (html) html.style.overflow = "hidden";
    } else {
      if (html) html.style.overflow = "unset";
    }
  }, [showContactPopup]);

  return null; // This component doesn't render anything
});

export default HtmlOverflowManager;
