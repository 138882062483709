import React, { useContext } from "react";
import Publication from "../../components/Publication/Publication";
import SegmentPageTemplate from "./TemplatePage";
import TitleDescriptionItem from "../../components/TitleDescriptionItem/TitleDescriptionItem";
import Slogen from "../../components/Slogen/Slogen";
import ContactUsBanner from "../../components/ContactUsBanner/ContactUsBanner";
import { imgFoBg } from "../../assets/assetsExport";
import { useSelector } from "react-redux";
import {
  getIsMobile,
  getIsNarrowScreen,
  getIsSmallScreen,
  getLang,
} from "../../features/app/appSlice";
import styles from "./TemplatePage.module.css";

export default function BusinessFamilyOfficePage() {
  const lang = useSelector(getLang);
  const isNarrowScreen = useSelector(getIsNarrowScreen);
  const isSmallScreen = useSelector(getIsSmallScreen);

  function OfferingsSection() {
    const offerings = [
      {
        title: { en: "Private Banking", cn: "私人银行" },
        description: {
          en: "Providing exclusive financial services, tailored investment strategies, and premium wealth management solutions.",
          cn: "提供专属金融服务、定制投资策略及高端财富管理方案。",
        },
      },
      {
        title: { en: "Legal Expertise", cn: "法律专家" },
        description: {
          en: "Offering legal consultation and compliance advice to ensure asset security and family succession planning.",
          cn: "提供法律咨询与合规建议，确保资产安全与家族传承规划。",
        },
      },
      {
        title: { en: "Tax Expertise", cn: "税务专家" },
        description: {
          en: "Optimizing tax structures and developing global tax compliance and tax-saving strategies.",
          cn: "优化税务架构，制定全球税务合规及节税策略。",
        },
      },
      {
        title: { en: "Asset Management", cn: "资管公司" },
        description: {
          en: "Managing investment portfolios and providing asset growth and wealth succession solutions.",
          cn: "管理投资组合，提供资产增值与财富传承解决方案。",
        },
      },
      {
        title: { en: "Real Estate Brokerage", cn: "地产经纪" },
        description: {
          en: "Selecting premium properties and offering real estate investment and global property services.",
          cn: "甄选优质房产，提供地产投资与全球置业服务。",
        },
      },
      {
        title: { en: "Insurance Services", cn: "保险公司" },
        description: {
          en: "Providing high-end insurance solutions to ensure long-term financial security for individuals and families.",
          cn: "提供高端保险方案，保障个人及家族长期财务安全。",
        },
      },
      {
        title: { en: "Accounting Services", cn: "会计公司" },
        description: {
          en: "Offering financial auditing, tax filing, and corporate financial planning services.",
          cn: "提供财务审计、税务申报及企业财务规划服务。",
        },
      },
      {
        title: { en: "Education Expertise", cn: "教育专家" },
        description: {
          en: "Providing top-tier global education planning and school selection consultation for children.",
          cn: "为子女提供全球顶级教育规划与择校咨询。",
        },
      },
    ];

    return (
      <div>
        <h2 className="header-border-t">
          {lang ? "Our Offerings" : "我们的服务"}
        </h2>
        <p className="header-description flex flex-col gap-4">
          <span>
            {lang
              ? "We act as your 'Wealth Steward,' employing the most scientific methods to assist you in discovering the most optimized wealth management and global asset allocation solutions, enabling you and your family to fully dedicate yourselves to your business and enjoy life."
              : '我们担任您的"财富管家"，用最科学的方法帮助您找到最优化的财富管理及全球资产配置解决方案，让您和您的家庭全身心投入事业和享受生活。'}
          </span>
          <span>
            {lang
              ? "Unlike many traditional wealth management companies that focus solely on investments, our diverse team of experts around the globe provides comprehensive wealth management consulting tailored to your needs. With over 10 years of industry experience and expertise, we create a highly private, professional, and sustainable family office for you and your family."
              : "与许多传统的财富管理公司只专注于投资不同，我们遍布全球的多元化专家团队能够为您的需求提供多维度全方位的财富管理咨询。凭借超过 10 年的行业经验和专业知识，我们为您和您的家人打造一个高度私密、专业和可持续的家族办公室。"}
          </span>
        </p>

        <div className="content-grid">
          {offerings.map((i) => {
            return (
              <TitleDescriptionItem
                title={lang ? i.title.en : i.title.cn}
                description={lang ? i.description.en : i.description.cn}
                leftLine={true}
              />
            );
          })}
        </div>
      </div>
    );
  }

  function OurTeamSection() {
    return (
      <div className="mt-20">
        <div className="flex gap-10 header-border-t-flex">
          <h2 className="whitespace-nowrap">
            {lang ? "Our Team &" : "我们的团队与"}
            <br />
            {lang ? "Eligibility" : "资质"}
          </h2>
          <p className="">
            {lang
              ? "Our founding team, all graduates of the University of Cambridge, utilizes years of academic achievements and industry expertise to create family office solutions that are highly forward-thinking, private, professional, and sustainable, tailored to the needs of you and your family."
              : "我们的创始团队均毕业于英国剑桥大学，用多年研学所成与行业专业积淀，以您和家族的需求为基石，定制专属于您的高度前瞻性、私隐性、专业性和可持续发展性的家族办公室。"}
          </p>
        </div>
        <div>
          <p className="text-left md:text-center mt-32 md:mt-20 mb-8 md:mb-4 text-black/70 text-sm">
            {lang
              ? "We are a licensed legal institution regulated by"
              : "我们是由以下机构监管的持牌法律机构"}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3  gap-x-6 gap-y-16">
            <div>
              <p className={styles.smallHeader}>
                {lang ? "OISC" : "OISC"}
                <span className="ml-2 font-text text-sm font-normal">
                  UK Home Office
                </span>
              </p>
              <p className={styles.smallDescription}>
                {lang
                  ? "Office of the Immigration Services Commissioner"
                  : "英国内政部移民事务署"}
              </p>
              <p className={styles.smallContent}>
                {lang
                  ? "Providing professional legal consulting and compliance services."
                  : "作为特许法律机构，我们在监管下为客户提供最专业的法律咨询和合规服务。"}
              </p>
            </div>

            <div>
              <p className={styles.smallHeader}>{lang ? "ILPA" : "ILPA"}</p>
              <p className={styles.smallDescription}>
                {lang
                  ? "Immigration Law Practitioners’ Association"
                  : "英国移民律师协会"}
              </p>
              <p className={styles.smallContent}>
                {lang
                  ? "As a certified member, we provide clients with authoritative and first-hand information on UK legal policies."
                  : "认证会员，为客户传递英国法律政策权威一手资讯。"}
              </p>
            </div>

            <div>
              <p className={styles.smallHeader}>{lang ? "STEP" : "STEP"}</p>
              <p className={styles.smallDescription}>
                {lang
                  ? "Society of Trust and Estate Practitioners"
                  : "英国信托及不动产执业协会"}
              </p>
              <p className={styles.smallContent}>
                {lang
                  ? "Providing high-net-worth individuals with expert and authoritative advice on trust structuring and planning."
                  : "会员 ，致力于为高净值人群提供信托架构和规划的专业权威建议。"}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <SegmentPageTemplate
      title={
        lang ? (
          <span>New Vision {isNarrowScreen ? <br /> : null} Family Office</span>
        ) : (
          <span className="text-5xl xxs:text-6xl">
            新愿景{isSmallScreen && <br />}家族办公室
          </span>
        )
      }
      description={
        lang
          ? "New Vision Family Office adheres to the philosophy of 'Understanding the changes in the times, professional planning and allocation, stable growth and legacy.' We provide one-stop services for ultra-high-net-worth Chinese families, including global asset allocation, wealth inheritance, trust structuring, tax planning, private banking, identity planning, children's education, and philanthropy."
          : "新愿景家族办公室（New Vision Family Oﬃce），秉承“洞悉时局变迁，专业规划配置，稳定增值传延”的理念，为中国的超高净值家族提供一站式全球资产配置、财富传承、信托架构、税务筹划、私人银行、身份规划、子女教育、慈善等服务。"
      }
      img={{ src: imgFoBg, alt: "people discussing" }}
    >
      <div className="content-container">
        <OfferingsSection />
      </div>
      <Slogen
        tag={lang ? "OUR PHILOSOPHY" : "我们的理念"}
        slogen={
          <p>
            {lang ? "Insight into Market Changes" : "洞察市场变化"}
            <br />
            {lang
              ? "Professional Planning & Allocation, Stable Growth & Legacy"
              : "专业规划与配置，稳定增长与传承"}
          </p>
        }
        subSlogen={
          lang
            ? "New Vision Family Office provides comprehensive one-stop global wealth management services for ultra-high-net-worth Chinese families. "
            : "新愿景家族办公室为超高净值的中国家庭提供全面的一站式全球财富管理服务。"
        }
      />
      <div className="content-container">
        <OurTeamSection />
        <Publication />
      </div>
      <ContactUsBanner />
    </SegmentPageTemplate>
  );
}
