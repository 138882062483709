import { combineReducers, configureStore } from "@reduxjs/toolkit";
import appReducer from "../features/app/appSlice";
import layoutsReducer from "../features/app/layoutsSlice";

// import { thunk } from 'redux-thunk'; // Import redux-thunk

// import { persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// const persistConfig: any = {
//     key: 'root',
//     storage,
//     blacklist: ['states, developments'] // Add this line to exclude the states slice from persistence
// };

const rootReducer = combineReducers({
  app: appReducer,
  layouts: layoutsReducer,
});

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"], // ignore 'persist/PERSIST' action
      },
    }),
});

// let persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { store };
// export { store, persistor };
