import React, { useContext } from "react";
import Publication from "../../components/Publication/Publication";
import SegmentPageTemplate from "./TemplatePage";
import TitleDescriptionItem from "../../components/TitleDescriptionItem/TitleDescriptionItem";
import Slogen from "../../components/Slogen/Slogen";
import ContactUsBanner from "../../components/ContactUsBanner/ContactUsBanner";
import {
  imgTechBg,
  logoCambridge,
  logoICL,
  logoOxford,
} from "../../assets/assetsExport";
import { useSelector } from "react-redux";
import {
  getIsMobile,
  getIsSmallScreen,
  getLang,
} from "../../features/app/appSlice";

export default function BusinessTechnologyPage() {
  const lang = useSelector(getLang);
  const isSmallScreen = useSelector(getIsSmallScreen);
  const isMobile = useSelector(getIsMobile);

  function OfferingsSection() {
    const offerings = [
      {
        title: { en: "Technology Investment Consulting", cn: "科技投资咨询" },
        description: {
          en: "Providing Chinese investors with in-depth market analysis and investment strategies, precisely connecting them with high-potential UK technology projects to ensure informed decision-making.",
          cn: "为中国投资者提供深入的市场分析及投资策略，精准对接英国高潜力科技项目，确保科学决策。",
        },
      },
      {
        title: {
          en: "Venture Acceleration & Scale-Up Support",
          cn: "创业加速与规模化支持",
        },
        description: {
          en: "Assisting UK university spin-offs and startups in acquiring capital, business resources, and market entry strategies to expand into the Chinese market and achieve business growth.",
          cn: "帮助英国大学衍生企业及初创公司获取资本、商业资源及市场准入策略，助力拓展中国市场并实现业务增长。",
        },
      },
      {
        title: {
          en: "University Technology Commercialisation",
          cn: "高校科技成果转化",
        },
        description: {
          en: "Helping UK universities and research institutions transform cutting-edge academic research into commercial products, bridging the gap between academia and industry.",
          cn: "协助英国大学及科研机构将前沿学术研究转化为商业化产品，打通学术与产业之间的桥梁。",
        },
      },
      {
        title: {
          en: "Cross-Border Fundraising & Capital Matching",
          cn: "跨境融资与资本对接",
        },
        description: {
          en: "Connecting UK technology enterprises with Chinese high-net-worth individuals, family offices, and institutional investors to facilitate efficient fundraising and partnerships.",
          cn: "连接英国科技企业与中国高净值人士、家族办公室及机构投资者，促进高效的融资与合作。",
        },
      },
      {
        title: {
          en: "UK-China Technology Exchange & Collaboration",
          cn: "中英科技交流与合作",
        },
        description: {
          en: "Organizing business visits, industry forums, and high-end exchange activities to promote deep cooperation and knowledge sharing between UK and Chinese tech ecosystems.",
          cn: "组织商务考察、行业论坛及高端交流活动，推动中英科技生态的深度合作与知识共享。",
        },
      },
    ];

    return (
      <div>
        <h2 className="header-border-t">
          {lang ? "Our Offerings" : "我们的服务"}
        </h2>
        <p className="header-description">
          {lang
            ? "With technology as our driving force, industry as our engine, and international talent as our foundation, we aim to build an interconnected innovation and entrepreneurship ecosystem between China and the UK."
            : "以科技为引领、产业为驱动、国际人才为支撑，我们致力于构建中英互联的创新创业生态。"}
        </p>
        <div className="content-grid">
          {offerings.map((i) => {
            return (
              <TitleDescriptionItem
                title={lang ? i.title.en : i.title.cn}
                description={lang ? i.description.en : i.description.cn}
                leftLine={true}
              />
            );
          })}
        </div>
      </div>
    );
  }

  function OurTeamSection() {
    return (
      <div className="header-border-t-flex">
        <h2>{lang ? "Our Team" : "我们的团队"}</h2>
        <div>
          <p>
            {lang
              ? "NVT’s founding team has over 15 years of experience in technology investment and management consulting in the UK."
              : "NVT的创始团队在英国的技术投资与管理咨询领域拥有超过15年的经验。"}
          </p>
          <p className="mt-4">
            {lang
              ? "Our mission is to efficiently link the UK's most advanced research and innovation projects with China's comprehensive industrial ecosystem and the world's largest single market. Through this platform, we promote the commercialization of university research outcomes, support the growth of university spin-off companies, and deepen UK-China technological cooperation."
              : "我们的使命是将英国最前沿的科研创新项目与中国完善的产业生态及全球最大单一市场高效连接。通过这一平台，我们推动高校科研成果商业化，支持大学衍生企业成长，并深化中英科技合作。"}
          </p>
        </div>
      </div>
    );
  }

  function OurPartnershipsSection() {
    const partnerships = [
      {
        img: { src: logoCambridge, alt: "University of Cambridge Logo" },
        title: { en: "University of Cambridge", cn: "剑桥大学" },
        description: {
          en: "A world-leading academic institution excelling in AI, biotechnology, and engineering, focusing on deep-tech innovation and commercialization of academic research.",
          cn: "全球领先的学术机构，在人工智能、生物技术和工程领域表现卓越，专注于深科技创新及学术研究商业化。",
        },
      },
      {
        img: { src: logoOxford, alt: "University of Oxford Logo" },
        title: { en: "University of Oxford", cn: "牛津大学" },
        description: {
          en: "Achieving breakthroughs in AI, medical sciences, and quantum computing, fostering innovation through interdisciplinary research and industry collaboration.",
          cn: "在人工智能、医学科学和量子计算领域取得突破性成果，通过跨学科研究与行业合作推动技术创新。",
        },
      },
      {
        img: { src: logoICL, alt: "Imperial College London Logo" },
        title: { en: "Imperial College London", cn: "伦敦帝国理工大学" },
        description: {
          en: "A global leader in engineering, AI, robotics, and medical technology, renowned for its applied research and strong industry partnerships.",
          cn: "在工程、人工智能、机器人技术和医疗科技方面处于全球领先地位，以应用研究和强大的产业合作而闻名。",
        },
      },
    ];
    return (
      <div className="mt-40 md:mt-20">
        <div className="header-border-t-flex">
          <h2>{lang ? "Our Partnerships" : "我们的合作伙伴"}</h2>
          <p>
            {lang
              ? "We maintain strong partnerships with leading institutions within the UK’s “Golden Triangle” of technology innovation."
              : "我们与英国“黄金三角”科技创新区域的顶尖学府保持紧密合作关系："}
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 mt-20 grid-with-divider gap-12 md:gap-0">
          {partnerships.map((i, index) => {
            return (
              <div className="flex-1 flex-grow">
                <TitleDescriptionItem
                  img={i.img}
                  title={lang ? i.title.en : i.title.cn}
                  description={lang ? i.description.en : i.description.cn}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <SegmentPageTemplate
      title={
        lang ? (
          <span>
            New Vision <br />
            Technology Investment
          </span>
        ) : (
          <span>新愿景{isMobile && <br />}科技投资</span>
        )
      }
      description={
        lang
          ? "New Vision Technology (NVT) is a technology investment consulting platform and accelerator based in London. We focus on connecting spin-off technology companies from top UK universities with Chinese capital and markets. Additionally, we offer commercial consulting and localized management services for high-net-worth investors, family businesses, and cross-border funds in China looking to invest in technology ventures."
          : "新愿景科技（NVT）是一家总部位于伦敦的科技投资咨询平台及企业加速器。我们专注于连接英国顶尖大学衍生科技企业与中国资本及市场，同时为中国高净值投资者、家族企业及跨境基金提供科技投资商业咨询及本地化管理服务。"
      }
      img={{ src: imgTechBg, alt: "blue background" }}
    >
      <div className="content-container">
        <OfferingsSection />
      </div>
      <Slogen
        tag={lang ? "OUR PHILOSOPHY" : "我们的理念"}
        slogen={
          <p>
            {lang ? (
              <span>
                Bridging Innovation, {isSmallScreen ? <br /> : null} Empowering
                Growth.
              </span>
            ) : (
              "连接创新，赋能成长。"
            )}
          </p>
        }
      />
      <div className="content-container">
        <OurTeamSection />
        <OurPartnershipsSection />
        <Publication />
      </div>
      <ContactUsBanner />
    </SegmentPageTemplate>
  );
}
