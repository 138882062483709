import React, { useEffect, useRef } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Header from "../layouts/Header/Header";
import Footer from "../layouts/Footer/Footer";
import AboutUsPage from "../pages/AboutUsPage/AboutUsPage";
import HomePage from "../pages/HomePage/HomePage";
import ContactUsPage from "../pages/ContactUsPage/ContactUsPage";
import BusinessArtCulture from "../pages/BusinessSegmentsPage/ArtCulture";
import BusinessCambridgeFoundation from "../pages/BusinessSegmentsPage/CambridgeFoundation";
import BusinessFamilyOfficePage from "../pages/BusinessSegmentsPage/FamilyOffice";
import BusinessPrivateClub from "../pages/BusinessSegmentsPage/PrivateClub";
import BusinessRealEstatePage from "../pages/BusinessSegmentsPage/RealEstate";
import BusinessTechnologyPage from "../pages/BusinessSegmentsPage/Technology";
import BusinessThinkTank from "../pages/BusinessSegmentsPage/ThinkTank";
import {
  PoliciesCookiesPage,
  PoliciesPrivacyPage,
  PoliciesTermsPage,
} from "../pages/Policies/PoliciesPage";
import Cookie from "../layouts/Cookie/Cookie";

// import { PoliciesCookies, PoliciesPrivacy, PoliciesTerms } from '../pages/Policies/Policies';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return null;
}

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <main>
        <Routes>
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route
            path="/business/family-office"
            element={<BusinessFamilyOfficePage />}
          />
          <Route
            path="/business/real-estate"
            element={<BusinessRealEstatePage />}
          />
          <Route
            path="/business/technology-investment"
            element={<BusinessTechnologyPage />}
          />
          <Route
            path="/business/art-and-culture"
            element={<BusinessArtCulture />}
          />
          <Route path="/business/think-tank" element={<BusinessThinkTank />} />
          <Route
            path="/business/private-club"
            element={<BusinessPrivateClub />}
          />
          <Route
            path="/business/cambridge-foundation"
            element={<BusinessCambridgeFoundation />}
          />
          <Route path="/policies/cookies" element={<PoliciesCookiesPage />} />
          <Route path="/policies/privacy" element={<PoliciesPrivacyPage />} />
          <Route path="/policies/terms" element={<PoliciesTermsPage />} />

          {/* <Route path="/insights" element={<InsightsPage />} /> */}
          <Route path="/" element={<HomePage />} />
          <Route
            path="/policies/terms-and-conditions"
            element={<PoliciesTermsPage />}
          />
          <Route
            path="/policies/privacy-policy"
            element={<PoliciesPrivacyPage />}
          />
          <Route
            path="/policies/cookies-policy"
            element={<PoliciesCookiesPage />}
          />
        </Routes>
      </main>
      <Cookie />
      <Footer />
    </BrowserRouter>
  );
}
