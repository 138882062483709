import React, { useContext } from "react";
import Publication from "../../components/Publication/Publication";
import SegmentPageTemplate from "./TemplatePage";
import TitleDescriptionItem from "../../components/TitleDescriptionItem/TitleDescriptionItem";
import Slogen from "../../components/Slogen/Slogen";
import ContactUsBanner from "../../components/ContactUsBanner/ContactUsBanner";
import {
  imgReBg,
  imgReMap,
  logoArla,
  logoCmp,
  logoDps,
  logoNrla,
  logoPrs,
} from "../../assets/assetsExport";
import { useSelector } from "react-redux";
import { getIsMobile, getLang } from "../../features/app/appSlice";
import styles from "./TemplatePage.module.css";
import { motion } from "framer-motion";
import CustomCursor from "../../components/Cursor/CustomCursor";

export default function BusinessRealEstatePage() {
  const lang = useSelector(getLang);
  const isMobile = useSelector(getIsMobile);
  const tooltipVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0 },
  };

  function OfferingsSection() {
    const offerings = [
      {
        title: { en: "Property Selection", cn: "房产甄选" },
        description: {
          en: "Carefully selecting premium properties based on your investment goals and personal preferences.",
          cn: "根据您的投资目标和个人喜好，精心挑选优质房源。",
        },
      },
      {
        title: { en: "Mortgage Assistance", cn: "银行贷款" },
        description: {
          en: "Coordinating with partner banks to facilitate loan applications and secure the best financing options.",
          cn: "与合作银行协调贷款申请，帮助您获得最佳融资方案。",
        },
      },
      {
        title: { en: "Legal Coordination", cn: "律师推荐" },
        description: {
          en: "Connecting you with experienced solicitors to ensure smooth and compliant property transactions.",
          cn: "对接经验丰富的律师，确保房产交易顺利合规。",
        },
      },
      {
        title: { en: "Tax Planning", cn: "税务规划" },
        description: {
          en: "Creating efficient tax strategies to optimize property investment and ownership costs.",
          cn: "制定高效的税务策略，优化房产投资与持有成本。",
        },
      },
      {
        title: { en: "Interior Design", cn: "家装设计" },
        description: {
          en: "Offering professional design services to enhance the aesthetics and functionality of your property.",
          cn: "提供专业设计服务，提升房产的美观与实用性。",
        },
      },
      {
        title: { en: "Leasing & Management", cn: "租赁管理" },
        description: {
          en: "Managing tenant selection, rent collection, and property maintenance for worry-free management.",
          cn: "负责租客筛选、租金收取、物业维护，实现无忧管理。",
        },
      },
      {
        title: { en: "School Placement", cn: "子女入学" },
        description: {
          en: "Assisting in selecting top schools and handling admission applications to provide quality education resources for your children.",
          cn: "协助挑选顶级学校并办理入学申请，为孩子提供优质教育资源。",
        },
      },
    ];

    return (
      <div>
        <h2 className="header-border-t">
          {lang ? "Our Offerings" : "我们的服务"}
        </h2>
        <p className="header-description">
          {lang
            ? "We offer essential value-added services in the home buying process, including bank loan assistance, legal coordination, tax planning, property leasing and management, and school placement services for children."
            : "我们提供购房流程中必不可少的增值服务，包括合作银行贷款、律师对接、税务规划、房屋租赁及管理、子女择校入学等安居服务。"}
        </p>
        <div className="content-grid">
          {offerings.map((i) => {
            return (
              <TitleDescriptionItem
                title={lang ? i.title.en : i.title.cn}
                description={lang ? i.description.en : i.description.cn}
                leftLine={true}
              />
            );
          })}
        </div>
      </div>
    );
  }

  function OurApproachesSection() {
    return (
      <div className="header-border-t-flex">
        <h2 className="">{lang ? "Our Approaches" : "我们的方法"}</h2>
        <div className="">
          <p className="mb-4">
            {lang
              ? "We carefully understand your needs and analyze industry and economic trends to select and integrate property information for you one-on-one. Our New Vision Real Estate expert team uses cutting-edge and exclusive data management and risk-based assessment strategies, combining qualitative and quantitative analysis methods. We conduct big data analysis on 25 sub-items such as the inherent value of the property, appreciation potential, and rent-to-sale ratio. After comprehensive weighted scoring, we identify the potential property areas that best meet your needs and integrate the optimal customized plan for you."
              : "我们悉心了解您的需求，根据行业与经济动态变化，审慎分析与筛选区域，为您一对一整合房源信息。我们新愿景地产专家团队，运用前沿和独家的数据管理与基于风险的评估策略 (Risk-Based Assessment) ，采用定性分析(Qualitative Analysis) 与定量分析(Quantitative Analysis) 相结合的方法，对房产的固有价值、升值潜力以及租售比等25个分项进行大数据分析，在综合加权评分之后，来定位最适合您需求的潜在置业区域，整合出专属于您的最优定制方案。"}
          </p>
          <div className="content-list">
            <p>{lang ? "REPORTS WE PROVIDE" : "我们提供的报告"}</p>
            <ul>
              <li>
                {lang
                  ? "Rental Property Investment Return Analysis"
                  : "房产出租投资回报分析"}
              </li>
              <li>
                {lang
                  ? "Area Safety and Social Demographic Analysis"
                  : "地区安全及社会人员分析"}
              </li>
              <li>
                {lang ? "Property Evaluation and Comparison" : "房源评估对比"}
              </li>
              <li>
                {lang
                  ? "Comprehensive 25-Dimension Assessment Chart"
                  : "综合评估25维度图"}
              </li>
              <li>{lang ? "Property Price Trend Analysis" : "房价走势分析"}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  function WhyUsSection() {
    return (
      <div className="mt-40">
        <div className="header-border-t-flex">
          <h2>{lang ? "Why Us" : "为什么选择我们"}</h2>
          <p>
            {lang
              ? "We have established partnerships with numerous property development projects across the UK and have developed advanced tools that enable clients to precisely filter and search for properties in London based on their personal preferences."
              : "我们与全英数百个房产开发项目建立了合作关系，并开发了先进的工具，使客户能够根据个人偏好精准筛选和搜索伦敦的房产。"}
          </p>
        </div>
        {/* <motion.a
          href="https://www.nvre.co.uk"
          target="_blank"
          rel="noopener noreferrer"
          className="relative block"
        >
          <img
            className="mt-10"
            src={imgReMap}
            alt={
              lang
                ? "a screen of our property search page, it redirects to the website"
                : "我们物业搜索页面的截图，它会重定向到网站"
            }
            data-cursor-text="Enter NVRE Website"
          />
        </motion.a>
        <CustomCursor /> */}
        <a
          href="https://www.nvre.co.uk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="mt-10"
            src={imgReMap}
            alt={
              lang
                ? "a screen of our property search page, it redirects to the website"
                : "我们物业搜索页面的截图，它会重定向到网站"
            }
            data-cursor-text="Enter NVRE Website"
          />
        </a>
      </div>
    );
  }

  function OurTeamSection() {
    const licenses = [
      {
        img: { src: logoArla, alt: "ARLA logo" },
        title: "ARLA Propertymark",
        description: {
          en: "The UK's leading property regulatory body, ensuring maximum protection for clients: client money protection, professional indemnity insurance, and membership in an independent arbitration scheme.",
          zh: "英国权威房产监管机构，确保客户享有最大的保护：资金保护、专业责任保险、加入独立仲裁机构等。",
        },
      },
      {
        img: { src: logoPrs, alt: "PRS logo" },
        title: "Property Redress Scheme",
        description: {
          en: "The primary purpose of the Property Redress Scheme certification is to resolve or handle consumer losses and complaints resulting from member actions.",
          zh: "物业管理计划认证的主要目的, 是解决或处理因会员的行为而导致的消费者损失与投诉。",
        },
      },
      {
        img: { src: logoDps, alt: "DPS logo" },
        title: "DPS",
        description: {
          en: "A government-authorized deposit protection scheme, ensuring tenant deposits are securely held and disputes are resolved fairly.",
          zh: "政府授权押金保护计划，确保租户押金安全存放并公正解决争议。",
        },
      },
      {
        img: { src: logoNrla, alt: "NRLA logo" },
        title: "NRLA",
        description: {
          en: "The UK's largest national association for private residential landlords, supporting and representing over 100,000 members.",
          zh: "英国最大的国家私人住宅房东协会组织，支持和代表超过十万名会员。",
        },
      },
      {
        img: { src: logoCmp, alt: "Property mark logo" },
        title: "Propertymark Client Money Protection",
        description: {
          en: "Under the protection of the Propertymark Client Money Protection scheme, landlords and clients are well-protected.",
          zh: "在Propertymark Client Money Protection客户资金保护计划的保护下，房东和客户得到了很好的保护。",
        },
      },
    ];
    return (
      <div className="mt-40">
        <div className="header-border-t-flex">
          <h2>
            {lang ? "Our Team &" : "我们的团队与"}
            <br />
            {lang ? "Eligibility" : "资格"}
          </h2>
          <p>
            {lang
              ? "Our founding team members, all graduates of the University of Cambridge, have over ten years of senior management experience and professional expertise in the real estate industry. Our professionalism is the primary reason why many clients choose us."
              : "我们的创始团队成员均毕业于剑桥大学，拥有地产行业十年以上高级管理经验和专业积淀，我们的专业性是广大客户选择我们的首要因素。"}
          </p>
        </div>
        <div>
          <p className="text-left md:text-center mt-32 md:mt-20 mb-8 md:mb-4 text-black/70 text-sm">
            {lang
              ? "We are a licensed legal institution regulated by"
              : "我们是受以下机构监管的持牌法律机构"}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3  gap-x-6 gap-y-16 mt-10">
            {licenses.map((i) => {
              return (
                <div className="">
                  <img className="h-12 mb-4" src={i.img.src} alt={i.img.alt} />
                  <p
                    className={`${`${styles.smallDescription} ${styles.noHeight}`} ${
                      styles.noHeight
                    }`}
                  >
                    {i.title}
                  </p>
                  <p className={styles.smallContent}>
                    {lang ? i.description.en : i.description.zh}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <SegmentPageTemplate
      title={
        lang ? (
          "New Vision Real Estate"
        ) : (
          <span>新愿景{isMobile && <br />}地产</span>
        )
      }
      description={
        lang
          ? "New Vision Real Estate, headquartered in London, is a high-end real estate consultancy certified by the UK's authoritative property regulatory body, ARLA Propertymark, dedicated to providing premium real estate investment projects for high-net-worth individuals."
          : "新愿景地产（New Vision Real Estate），是一家总部位于伦敦的高端地产咨询公司，受英国权威房产监管机构ARLA Propertymark认证，致力于为高净值人群提供优质地产投资项目。"
      }
      img={{ src: imgReBg, alt: "real estate image" }}
    >
      <div className="content-container">
        <OfferingsSection />
      </div>
      <Slogen
        tag={lang ? "OUR PHILOSOPHY" : "我们的理念"}
        slogen={
          lang ? (
            <p>
              Whether it is a
              <br />
              <span className="">primary residence</span>
              ,&nbsp;
              <span className="">holiday home</span>
              ,&nbsp;or&nbsp;
              <span className="">investment</span>
              <br />
              <span className="block h-3"></span>
              we are here to help you to find the{" "}
              <span className=" italic text-main font-poster font-bold text-2xl">
                right property
              </span>{" "}
              in the{" "}
              <span className=" italic text-main font-poster font-bold text-2xl">
                right location
              </span>
              .
            </p>
          ) : (
            <p>
              不论您是自住还是投资房产，
              <br />
              我们都会尽专业所能，
              <br />
              用心帮助您和家人找到 <span className="text-main">
                合适的项目
              </span>{" "}
              和 <span className="text-main">理想的地点</span>。
            </p>
          )
        }
      />
      <div className="content-container">
        <OurApproachesSection />
        <WhyUsSection />
        <OurTeamSection />
        <Publication />
      </div>
      <ContactUsBanner />
    </SegmentPageTemplate>
  );
}
