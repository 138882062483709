import React, { useState, ChangeEvent, FormEvent, useContext } from "react";
import styles from "./ContactUsPage.module.css";
import qrChat from "../../assets/media/company/qr-chat.png";
import qrFollow from "../../assets/media/company/qr-follow.png";
import Spinner from "../../components/Spinner/Spinner";
import { sendEmail } from "../../api/email";
import Publication from "../../components/Publication/Publication";
import { imgOffice } from "../../assets/assetsExport";
import { useSelector } from "react-redux";
import { getLang } from "../../features/app/appSlice";

export default function ContactUsPage() {
  const lang = useSelector(getLang);
  return (
    <div className={`${styles.contactUsPageContainer} mt-40 content-container`}>
      <p className="tag">{lang ? "FIND US" : "找到我们"}</p>
      <h1 className="font-poster text-6xl mt-10 border-b border-black pb-4">
        {lang ? "Contact Us" : "联系我们"}
      </h1>
      <div className="bg-secondary-light mt-6 mb-20 p-6 flex flex-col sm:flex-row justify-between gap-6">
        <div className="order-2 sm:order-1">
          <p className="font-header text-3xl mb-8 leading-normal">
            {lang ? (
              <span>
                New Vision
                <br />
                International Group
                <br />
                Headquarter
              </span>
            ) : (
              <span>
                新愿景国际
                <br />
                集团总部
              </span>
            )}
          </p>
          <div className="flex flex-col gap-2 font-medium font-text text-sm">
            <p>
              16 Berkeley Street, Mayfair
              <br />
              London, W1J 8DZ, United Kingdom
            </p>
            <p>+44 (0)20 3741 8080</p>
            <p>
              <a href="mailto:info@newvgroup.com">info@newvgroup.com</a>
            </p>
          </div>

          <div className="flex mt-10">
            <div>
              <img
                className="w-20"
                src={qrChat}
                alt={lang ? "QR code for wechat" : "微信二维码"}
              />
              <p className="text-center text-xs">Chat with Us</p>
            </div>
            <div>
              <img
                className="w-20"
                src={qrFollow}
                alt={lang ? "QR code for wechat" : "微信二维码"}
              />
              <p className="text-center text-xs">Follow Us</p>
            </div>
          </div>
        </div>
        <img
          className="w-full sm:w-[60%] object-cover order-1 sm:order-2"
          src={imgOffice}
          alt=""
        />
      </div>
      <div className="header-border-t-flex">
        <h2>{lang ? "Contact" : "联系"}</h2>
        <ContactForm />
      </div>
      <Publication />
    </div>
  );
}

type FormData = {
  name: string;
  email: string;
  subject: string;
  content: string;
};

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    subject: "",
    content: "",
  });
  const [response, setResponse] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const lang = useSelector(getLang);
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    console.log({ name, value });
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (
      !formData.name.trim() ||
      !formData.email.trim() ||
      !formData.content.trim()
    ) {
      setResponse(lang ? "Please fill all * fields." : "请填写所有 * 字段。");
      return;
    }
    if (!formData.email.trim().replace(" ", "").includes("@")) {
      setResponse(
        lang
          ? "Please input a valid email address."
          : "请输入有效的电子邮件地址。"
      );
      return;
    }
    setDisableButton(true);
    const response = await sendEmail({
      name: formData.name,
      email: formData.email,
      content: formData.content,
      subject: formData.subject,
    });
    if (response === 200) {
      setResponse(lang ? "Email sent successfully" : "电子邮件发送成功");
      setFormData({
        name: "",
        email: "",
        subject: "",
        content: "",
      });
    } else {
      setResponse(lang ? "Email failed" : "电子邮件发送失败");
    }
    setDisableButton(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4 mt-16 sm:mt-0 w-full"
    >
      <div className="flex gap-3">
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder={lang ? "Name*" : "姓名*"}
        />
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder={lang ? "Email*" : "邮箱*"}
        />
      </div>
      <input
        type="text"
        id="subject"
        name="subject"
        value={formData.subject}
        onChange={handleChange}
        placeholder={lang ? "Subject" : "主题"}
      />
      <textarea
        name="content"
        id="content"
        value={formData.content}
        onChange={handleChange}
        placeholder={lang ? "Message*" : "信息*"}
        className="h-52"
      />
      <button
        type="submit"
        disabled={disableButton}
        className={`bg-main mt-6 text-white text-sm font-text px-7 py-1 self-start hover:bg-secondary-dark hover:text-main hover:border-main ${
          disableButton ? "opacity-70" : ""
        }`}
      >
        {disableButton ? <Spinner /> : lang ? "SEND MESSAGE" : "发送信息"}
      </button>
      {response && <p>{response}</p>}
    </form>
  );
};
