import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Header.module.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link";
import {
  getLayoutHeaderHeight,
  setLayoutValue,
} from "../../features/app/layoutsSlice";
import { getLang, setLang } from "../../features/app/appSlice";
import { getIsSmallScreen } from "../../features/app/appSlice";
import { IconClose, IconMenu, IconRight } from "../../assets/svgExport";
import {
  imgAboutBg,
  imgOurFirmBg,
  logoNVIG,
  logoNVIGDark,
} from "../../assets/assetsExport";

function Header(props: any) {
  const [showMenu, setShowMenu] = useState(false);
  const headerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const lang = useSelector(getLang);
  const isSmallScreen = useSelector(getIsSmallScreen);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");
  const headerHeight = useSelector(getLayoutHeaderHeight);
  const [isHovered, setIsHovered] = useState(false);
  const [whiteBackground, setWhiteBackground] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const [expandedHeaders, setExpandedHeaders] = useState<{
    [key: string]: boolean;
  }>({});
  const [showExpanded, setShowExpanded] = useState(false);
  const navigate = useNavigate();

  const hideMobileHeader = (e: any) => {
    e.stopPropagation();
    setShowMenu(false);
    setShowExpanded(false);
    setIsHovered(false);
    setExpandedHeaders({});
    if (document.querySelector("html")) {
      document.querySelector("html")!.style.overflow = "visible";
    }
  };

  const toggleMenu = (bool: boolean) => {
    if (document.querySelector("html")) {
      document.querySelector("html")!.style.overflow = bool
        ? "hidden"
        : "visible";
    }
    setShowMenu(bool);
    setExpandedHeaders({});
    setShowExpanded(false);
  };

  function ExpandableHeader({
    title,
    headerKey,
  }: {
    title: any;
    headerKey: string;
  }) {
    return (
      <p
        id={`header-${headerKey}`}
        className={`relative h-full flex gap-1 items-center font-medium hover:cursor-default ${
          isSmallScreen ? "" : "px-3 py-1 my-3"
        } ${
          currentPath.includes("/" + headerKey) || expandedHeaders[headerKey]
            ? "text-main bg-white/55 rounded-xl"
            : ""
        }`}
        onMouseEnter={
          isSmallScreen
            ? undefined
            : (e: any) => {
                if (title.en !== "Contact Us") {
                  setExpandedHeaders({
                    business: headerKey === "business",
                    about: headerKey === "about",
                  });
                  setShowExpanded(true);
                }
              }
        }
        onClick={
          !isSmallScreen
            ? undefined
            : (e: any) => {
                if (title.zh !== "联系我们") {
                  setExpandedHeaders({
                    business: headerKey === "business",
                    about: headerKey === "about",
                  });
                  setShowExpanded(true);
                }
              }
        }
      >
        {lang ? title.en : title.zh}
        {isSmallScreen && (
          <IconRight width="16" height="16" className="opacity-70" />
        )}
      </p>
    );
  }

  useEffect(() => {
    let resizeTimer: ReturnType<typeof setTimeout>;

    const handleResize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        if (headerRef.current && headerRef.current.offsetHeight > 0) {
          dispatch(
            setLayoutValue({
              name: "headerHeight",
              value: headerRef.current.offsetHeight,
            })
          );
        }
      }, 300);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      if (resizeTimer) clearTimeout(resizeTimer);
    };
  }, [dispatch]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const result =
      (isSmallScreen && showMenu) ||
      (!isSmallScreen && showExpanded) ||
      isHovered;
    setWhiteBackground(result);
  }, [isSmallScreen, showMenu, showExpanded, isHovered]);

  function Headers({ isSmallScreen }: { isSmallScreen: boolean }) {
    return (
      <div className={`flex text-sm ${isSmallScreen ? "flex-col gap-4" : ""}`}>
        <ExpandableHeader
          title={{ en: "Our Businesses", zh: "我们的业务" }}
          headerKey="business"
        />
        <ExpandableHeader
          title={{ en: "About Us", zh: "关于我们" }}
          headerKey="about"
        />
        <HashLink
          id="header-contact"
          className={`${
            isSmallScreen ? "" : "px-6 py-4"
          } hover:text-main  h-full flex items-center ${
            currentPath.includes("/contact") ? " text-main" : ""
          }`}
          to="/contact"
          onMouseEnter={() => {
            !isSmallScreen && setShowExpanded(false);
            setIsHovered(true);
          }}
          onMouseLeave={() => setIsHovered(false)}
          onClick={hideMobileHeader}
        >
          <p className="">{lang ? "Contact Us" : "联系我们"}</p>
        </HashLink>
        <p
          className={`hover:cursor-pointer hover:text-main ${
            isSmallScreen ? "" : "px-6 py-4"
          }`}
          onClick={() => dispatch(setLang(!lang))}
        >
          {lang ? "中文" : "English"}
        </p>
      </div>
    );
  }

  const transparentBgHeader =
    (currentPath === "/" || currentPath === "/about") &&
    !isScrolled &&
    !showMenu &&
    !showExpanded;

  useEffect(() => {
    // Preload imgOurFirmBg
    const imgOurFirm = new Image();
    imgOurFirm.src = imgOurFirmBg;

    // Preload imgAboutBg
    const imgAbout = new Image();
    imgAbout.src = imgAboutBg;
  }, []);

  return (
    <>
      <header
        className={`fixed top-0 left-0  w-screen z-[9999]  font-medium  
          ${
            transparentBgHeader
              ? "bg-transparent text-white/80"
              : "bg-main-light-blue text-black/80"
          }
        
        `}
        ref={headerRef}
        onMouseLeave={() => {
          if (isSmallScreen) return;

          setShowExpanded(false);
          setExpandedHeaders({});
        }}
      >
        {/* Top section - Logo and Navigation */}
        <div className={`${isSmallScreen ? "py-3 pr-4" : ""} `}>
          <nav className="flex justify-between items-center">
            <NavLink
              id="header-logo"
              to="/"
              className="pl-4 mobile:pl-6 sm:pl-10 hover:cursor-pointer"
              onClick={hideMobileHeader}
            >
              <img
                className={`h-6 opacity-90 object-contain`}
                src={transparentBgHeader ? logoNVIG : logoNVIGDark}
                alt="nvfo-logo"
              />
            </NavLink>

            {!isSmallScreen && <Headers isSmallScreen={isSmallScreen} />}

            {isSmallScreen && (
              <div
                className="hover:cursor-pointer"
                onClick={() => toggleMenu(!showMenu)}
              >
                {showMenu ? (
                  <IconClose height="20" width="20" style={{ fill: "gray" }} />
                ) : (
                  <IconMenu
                    height="20"
                    width="20"
                    // style={{ fill: "gray" }}
                    style={{ fill: transparentBgHeader ? "white" : "gray" }}
                  />
                )}
              </div>
            )}
          </nav>
        </div>

        {/* Expanded section */}
        <div
          className={`${
            isSmallScreen ? "flex flex-row justify-between" : ""
          } transition-[height] duration-300 ease-in-out overflow-hidden bg-white border-t shadow-xl shadow-secondary-dark/5 `}
          style={{
            height: !isSmallScreen
              ? showExpanded
                ? "300px"
                : "0"
              : showMenu
              ? "100vh"
              : "0",
            visibility:
              (!isSmallScreen && showExpanded) || (isSmallScreen && showMenu)
                ? "visible"
                : "hidden",
          }}
        >
          {isSmallScreen && (
            <div className="m-4 mobile:m-10 text-nowrap">
              <Headers isSmallScreen={isSmallScreen} />
            </div>
          )}
          <div
            className={`border-b-[0.5px] border-b-gray-300/30 flex gap-4 w-full h-full ${
              isSmallScreen ? "flex-col" : ""
            }`}
          >
            <div className={`${isSmallScreen ? "auto" : "h-full"}`}>
              {expandedHeaders["business"] && (
                <div
                  className={`${
                    isSmallScreen
                      ? "w-full h-[260px]"
                      : "w-[320px] md:w-[400px] h-full "
                  } relative`}
                >
                  <div className="relative h-full w-full">
                    <img
                      className="h-full w-full object-cover brightness-75"
                      src={imgOurFirmBg}
                      alt="business"
                      loading="eager"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
                  </div>

                  <p className="font-main-sans absolute bottom-0 left-0 p-4 sm:p-10 text-white flex flex-col gap-2">
                    <span className="text-4xl font-poster">
                      {lang ? "Our Businesses" : "我们的业务"}
                    </span>
                    <span className="text-sm font-normal leading-relaxed opacity-70">
                      {lang
                        ? "Our businesses cover family office, real estate, technology investment, art and culture, think tank, private club, and Cambridge Foundation."
                        : "我们的业务涵盖家族办公室、房地产、科技投资、艺术与文化、智库、私人俱乐部、剑桥基金会。"}
                    </span>
                  </p>
                </div>
              )}
              {expandedHeaders["about"] && (
                <div
                  className={`${
                    isSmallScreen
                      ? "w-full h-[260px]"
                      : "w-[320px] md:w-[400px] h-full "
                  } relative`}
                >
                  <div className="relative h-full w-full">
                    <img
                      className="h-full w-full object-cover brightness-75"
                      src={imgAboutBg}
                      alt="about"
                      loading="eager"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
                  </div>

                  <p className="font-main-sans absolute bottom-0 left-0 p-4 sm:p-10 text-white flex flex-col gap-2">
                    <span className="text-4xl font-poster">
                      {lang ? "About Us" : "关于我们"}
                    </span>
                    <span className="text-sm font-normal leading-relaxed opacity-70">
                      {lang
                        ? "About us includes our company, our mission and values, compliance and legality."
                        : "关于我们包括我们的公司、我们的宗旨和价值观、合规性和合法性。"}
                    </span>
                  </p>
                </div>
              )}
            </div>
            <div
              className={` ${
                isSmallScreen
                  ? "overflow-y-scroll pt-6 pb-20"
                  : "p-4 pt-5 md:p-10"
              }`}
            >
              {expandedHeaders["business"] && (
                <div
                  className={`flex flex-col gap-2 ${
                    isSmallScreen ? "mb-20 p-2" : ""
                  } `}
                >
                  {[
                    {
                      to: "/business/family-office",
                      title: { en: "Family Office", zh: "家族办公室" },
                    },
                    {
                      to: "/business/real-estate",
                      title: { en: "Real Estate", zh: "房地产" },
                    },
                    {
                      to: "/business/technology-investment",
                      title: { en: "Technology Investment", zh: "科技投资" },
                    },
                    {
                      to: "/business/art-culture",
                      title: { en: "Art and Culture", zh: "艺术与文化" },
                    },
                    {
                      to: "/business/private-club",
                      title: { en: "Private Club", zh: "私人俱乐部" },
                    },
                    {
                      to: "/business/think-tank",
                      title: { en: "Think Tank", zh: "智库" },
                    },
                    {
                      to: "/business/cambridge-foundation",
                      title: { en: "Cambridge Foundation", zh: "剑桥基金会" },
                    },
                  ].map((item, itemIndex) => (
                    <NavLink
                      key={itemIndex}
                      to={item.to}
                      onClick={hideMobileHeader}
                      style={{
                        fontWeight: currentPath.includes(item.to)
                          ? "bold"
                          : "auto",
                      }}
                      className="flex gap-3 items-start hover:opacity-80 hover:cursor-pointer"
                    >
                      <div className="bg-main/10 rounded-md p-2 w-2 mt-1 hover:cursor-pointer">
                        {/* {item.icon} */}
                      </div>
                      <p className="hover:cursor-pointer">
                        {lang ? item.title.en : item.title.zh}
                      </p>
                    </NavLink>
                  ))}
                </div>
              )}
              {expandedHeaders["about"] && (
                <div
                  className={`flex flex-col gap-2 ${
                    isSmallScreen ? "mb-20 p-2" : ""
                  } `}
                >
                  {[
                    {
                      to: "/about",
                      title: { en: "Our Firm", zh: "我们的公司" },
                    },
                    {
                      to: "/about#purposes-values",
                      title: {
                        en: "Purposes and Values",
                        zh: "我们的宗旨和价值观",
                      },
                    },
                    {
                      to: "/about#compliance-legality",
                      title: {
                        en: "Compliance and Legality",
                        zh: "合规性和合法性",
                      },
                    },
                  ].map((item, itemIndex) => (
                    <HashLink
                      key={itemIndex}
                      to={item.to}
                      onClick={hideMobileHeader}
                      className="flex gap-3 items-start hover:opacity-80 hover:cursor-pointer"
                    >
                      <div className="bg-main/10 rounded-md p-2 w-2 mt-1 hover:cursor-pointer">
                        {/* {item.icon} */}
                      </div>
                      <p className="hover:cursor-pointer">
                        {lang ? item.title.en : item.title.zh}
                      </p>
                    </HashLink>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
