import React, { useContext } from "react";
import { imgJudgeBusinessSchool } from "../../assets/assetsExport";
import { useSelector } from "react-redux";
import { getIsNarrowScreen, getLang } from "../../features/app/appSlice";
import SlideUpAnimation from "../SlideUpAnimation/SlideUpAnimation";

export default function Publication({
  withTitle = false,
}: {
  withTitle?: boolean;
}) {
  const lang = useSelector(getLang);
  const isSmallScreen = useSelector(getIsNarrowScreen);
  return (
    <div className="border-t border-black pt-6  my-32">
      {withTitle && (
        <h2 className="font-header text-3xl mb-10 leading-normal">
          {lang ? "University of Cambridge Publication" : "剑桥大学出版物"}
        </h2>
      )}
      <div className="flex flex-col sm:flex-row bg-secondary-light ">
        <img
          className="w-full h-[300px] sm:h-auto sm:w-1/2 object-cover"
          src={imgJudgeBusinessSchool}
          alt="剑桥Judge商学院"
        />
        <div className="p-10">
          <p className="font-medium pb-4">{lang ? "FEATURED" : "特别报道"}</p>
          <p className="font-header text-3xl flex flex-col lg:flex-row">
            {lang ? (
              <span>New vision for Cambridge EMBA alumni</span>
            ) : (
              <>
                <span>剑桥EMBA校友的</span>
                <span>新愿景</span>
              </>
            )}
          </p>
          <p className="py-4 text-sm mb-4">
            {lang
              ? "New Vision Group continues to achieve remarkable success in its development. The University of Cambridge conducted an exclusive interview with the founding management team of New Vision, sharing the group's development philosophy and significant achievements."
              : "新愿景集团在发展中不断收获喜讯。英国剑桥大学专访了新愿景创始管理团队，分享集团的发展理念和显著成就。"}
          </p>
          <a
            href="https://www.jbs.cam.ac.uk/2021/new-vision-for-cambridge-emba-alumni/"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-black text-white px-3 py-1 font-medium text-sm"
          >
            {lang ? "READ MORE" : "阅读更多"}
          </a>
        </div>
      </div>
    </div>
  );
}
