import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { logoNVIG } from "../../assets/assetsExport";

interface LayoutState {
  headerHeight: number;
  menubarHeight: number;
  [key: string]: number;
  imgSrc: any;
}
// INITIAL STATE
const initialState = {
  headerHeight: 0,
  menubarHeight: 0,
  imgSrc: logoNVIG,
};

// SLICE OBJECT
export const layoutsSlice = createSlice({
  name: "layouts",
  initialState,
  reducers: {
    setLayoutValue: (
      state: LayoutState,
      action: PayloadAction<{ name: string; value: number }>
    ) => {
      state[action.payload.name] = action.payload.value;
    },
    setImgSrc: (state, action) => {
      state.imgSrc = action.payload;
    },
  },
});

// SELECTORS
export const getLayoutHeaderHeight = (state: any) => state.layouts.headerHeight;
export const getLayoutMenubarHeight = (state: any) =>
  state.layouts.menubarHeight;
export const getImgSrc = (state: any) => state.layouts.imgSrc;
// EXPORTS
export const { setLayoutValue, setImgSrc } = layoutsSlice.actions;
export default layoutsSlice.reducer;
