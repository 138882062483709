import React, { useContext, useEffect, useMemo, useRef } from "react";
import { NavLink } from "react-router-dom";
import { getLang } from "../../features/app/appSlice";
import { useSelector } from "react-redux";

export default function SegmentPageTemplate({
  children,
  title,
  description,
  img,
}: {
  children: any;
  title: any;
  description: string;
  img: any;
}) {
  const url = window.location.pathname.split("/").pop() || ""; // get the "section" from the URL
  const lang = useSelector(getLang);
  const segments = useMemo(
    () => [
      {
        url: "family-office",
        title: { en: "Family Office", cn: "家族办公室" },
      },
      { url: "real-estate", title: { en: "Real Estate", cn: "房地产" } },
      {
        url: "technology-investment",
        title: { en: "Technology Investment", cn: "科技投资" },
      },
      {
        url: "art-and-culture",
        title: { en: "Art & Culture", cn: "艺术与文化" },
      },
      { url: "private-club", title: { en: "Private Club", cn: "私人俱乐部" } },
      { url: "think-tank", title: { en: "Think Tank", cn: "智库" } },
      {
        url: "cambridge-foundation",
        title: { en: "Cambridge Foundation", cn: "剑桥基金会" },
      },
    ],
    []
  ); // Empty dependency array means this will only run once

  const navLinkRefs = useRef<(HTMLAnchorElement | null)[]>([]);
  useEffect(() => {
    // Find the index of the selected segment
    const selectedIndex = segments.findIndex(
      (segment: any) => segment.url === url
    );
    if (selectedIndex !== -1 && navLinkRefs.current[selectedIndex]) {
      // Scroll the selected NavLink into view
      navLinkRefs.current[selectedIndex]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "center",
      });
    }
  }, [url, segments]);

  return (
    <div className="mt-20 md:mt-32">
      <div className="p-6 sm:p-10 ">
        <p className="font-medium">{lang ? "WHAT WE DO" : "为您服务"}</p>
        <p className="text-5xl font-poster pt-8 pb-4">
          {lang ? "Our Businesses" : "我们的业务"}
        </p>
        <div className="border-b border-black mb-4 flex gap-6 whitespace-nowrap overflow-x-scroll hide-scrollbar">
          {segments.map((i: any, index: any) => {
            return (
              <NavLink
                key={i.url}
                to={"/business/" + i.url}
                className={`${
                  i.url === url ? "font-bold border-b-2 border-black" : ""
                }`}
                ref={(el) => (navLinkRefs.current[index] = el)}
              >
                {lang ? i.title.en : i.title.cn}
              </NavLink>
            );
          })}
        </div>
        <div className="relative">
          <div className="relative">
            <img
              className="h-[400px] object-cover object-center w-full"
              src={img.src}
              alt={
                title +
                (lang
                  ? "'s background decorative image. "
                  : "的背景装饰图像。") +
                img.alt
              }
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent"></div>
          </div>
          <h1 className="absolute bottom-3 left-6 pr-6 text-white text-6xl font-poster leading-tight">
            {title}
          </h1>
        </div>
        <p className="mt-6 mb-20">{description}</p>
      </div>
      {children}
    </div>
  );
}
