import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider, useSelector } from "react-redux";
import ReactGA from "react-ga4";
import "tailwindcss/tailwind.css";
import { getCookieStateAnalytics } from "./features/app/appSlice";
import { store } from "./app/store";
import App from "./app/App";

ReactGA.initialize("G-PN1DSW3WKE");

const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
};

const RootComponent = () => {
  const analytics = useSelector(getCookieStateAnalytics);
  useEffect(() => {
    if (analytics) {
      reportWebVitals(SendAnalytics);
    }
  }, [analytics]);

  return <App />;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <PersistGate loading={null} persistor={persistor}> */}
      <RootComponent />
      {/* </PersistGate> */}
    </Provider>
  </React.StrictMode>
);
