import React from "react";
import "./App.css";
import AppRoutes from "./AppRoutes";
import "tailwindcss/tailwind.css";
import UserSettingManager from "./managers/UserSettingManager";
import ResizeManager from "./managers/ResizeManager";
import HtmlOverflowManager from "./managers/HtmlOverflowManager";

function App() {
  return (
    <>
      <UserSettingManager />
      <ResizeManager />
      <HtmlOverflowManager />
      <AppRoutes />
    </>
  );
}

export default App;
