import React, { useContext } from "react";
import Publication from "../../components/Publication/Publication";
import SegmentPageTemplate from "./TemplatePage";
import ContactUsBanner from "../../components/ContactUsBanner/ContactUsBanner";
import { imgVclubBg, imgVclubEvents } from "../../assets/assetsExport";
import { useSelector } from "react-redux";
import { getLang } from "../../features/app/appSlice";

export default function BusinessPrivateClub() {
  const lang = useSelector(getLang);
  function AboutSection() {
    return (
      <div className="header-border-t-flex">
        <h2>{lang ? "About" : "关于"}</h2>
        <div>
          <p>
            {lang
              ? "As the global center of private clubs, London upholds a rich tradition of elite social circles. V Club welcomes members passionate about art collection, yachting, aviation, golf, fine wine, and more."
              : "兴起于英国的私人俱乐部制度，让伦敦成为当之无愧的全球私人俱乐部中心。我们欢迎对艺术收藏、帆船游艇、飞行、高尔夫等感兴趣的高净值人士加入。"}
          </p>
          <div className="content-list">
            <p>{lang ? "REGULAR PRIVATE EVENTS IN" : "定期私人活动包括"}</p>
            <ul>
              <li>{lang ? "Art exhibitions" : "艺术展览"}</li>
              <li>{lang ? "Auctions" : "拍卖会"}</li>
              <li>{lang ? "Polo matches" : "马球会"}</li>
              <li>{lang ? "Wine Tastings" : "酒会"}</li>
              <li>{lang ? "Social Gatherings" : "社交聚会"}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  function PastEventsSection() {
    return (
      <div className="mt-36">
        <div className="header-border-t-flex mb-10">
          <h2>{lang ? "Past Events" : "往期活动"}</h2>
          <p>
            {lang
              ? "Allowing members to immerse themselves in the finest aspects of British life while expanding their interests and connections."
              : "在这里享受英国高端生活乐趣、发展兴趣爱好、扩展人脉网络。"}
          </p>
        </div>
        <img src={imgVclubEvents} alt="past events photos" />
      </div>
    );
  }

  return (
    <SegmentPageTemplate
      title="V Club"
      description={
        lang
          ? "V Club is an exclusive private lifestyle club in London, offering a sophisticated social and interest-based networking platform for high-net-worth individuals."
          : "V Club是位于英国伦敦的高端生活方式私人俱乐部，为志同道合的高净值人士提供领略英国文化、广交良友和思想碰撞的平台。"
      }
      img={{ src: imgVclubBg, alt: "a private card" }}
    >
      <div className="content-container">
        <AboutSection />
        <PastEventsSection />
        <Publication />
      </div>
      <ContactUsBanner />
    </SegmentPageTemplate>
  );
}
