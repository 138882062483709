import React, { useState, useRef, useContext } from "react";
import "./Cookie.scss";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getLang } from "../../features/app/appSlice";
import {
  getCookieStateAnalytics,
  getCookieStatePreference,
  getCookieStatePrivacy,
  setCookieState,
} from "../../features/app/appSlice";
import { AppDispatch } from "../../app/store";

export default function Cookie() {
  const lang = useSelector(getLang);
  const [preferenceOpen, setPreferenceOpen] = useState(false);
  const analytics = useSelector(getCookieStateAnalytics);
  const preference = useSelector(getCookieStatePreference);
  const privacyFromLocalStorage = localStorage.getItem("cookiePrivacy") === "1";
  const privacyFromState = useSelector(getCookieStatePrivacy);
  const privacy = privacyFromLocalStorage || privacyFromState;
  const dispatch = useDispatch<AppDispatch>();
  const cookieRef = useRef<HTMLDivElement>(null);
  const [analyticsLocal, setAnalyticsLocal] = useState(analytics);
  const [preferenceLocal, setPreferenceLocal] = useState(preference);

  function savePreferences(analytics: any, preference: any) {
    localStorage.setItem("cookieAnalytics", analytics);
    localStorage.setItem("cookiePreference", preference);
    localStorage.setItem("cookiePrivacy", "1");
    dispatch(
      setCookieState({
        privacy: 1,
        preference: preference,
        analytics: analytics,
      })
    );
    if (cookieRef.current) {
      cookieRef.current.style.display = "none";
    }
  }

  if (privacy) {
    return;
  }

  return (
    <div className="banner-cookie-container" ref={cookieRef}>
      <div className="cookie-banner">
        <h2>{lang ? "This site uses cookies" : "本网站使用cookies"}</h2>
        <p>
          {lang
            ? "We use cookies and similar technologies to personalise content and analyse website performance. To learn more, read our"
            : "我们使用cookies和类似技术来个性化内容和分析网站性能。要了解更多信息，请阅读我们的"}{" "}
          <NavLink
            to="/policies/cookies"
            onClick={() => {
              setPreferenceOpen(false);
            }}
            className="text-main"
          >
            {lang ? "Cookies Policy" : "Cookie 使用政策"}
          </NavLink>
          .
        </p>
        <div className="buttons">
          <button
            onClick={() => {
              savePreferences(1, 1);
            }}
          >
            {lang ? "Agree All" : "同意所有"}
          </button>
          <button
            onClick={() => {
              savePreferences(0, 0);
            }}
          >
            {lang ? "Reject All" : "拒绝所有"}
          </button>
          <button
            onClick={() => {
              setPreferenceOpen(!preferenceOpen);
            }}
          >
            {lang ? "Preferences" : "偏好设置"}
            {preferenceOpen ? <span>-</span> : <span>+</span>}
          </button>
        </div>
      </div>
      <div
        className={`manage-cookies-container ${preferenceOpen ? "open" : ""}`}
      >
        <ul className={`manage-cookies`}>
          <li>
            <div>
              <h3>{lang ? "Necessary" : "必要"}</h3>
              <p style={{ fontWeight: "400" }}>{lang ? "Required" : "必需"}</p>
            </div>
            <p>
              {lang
                ? "Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or checking out to register."
                : "必要cookies用于启用本网站的基本功能，例如提供安全的登录或结账注册。"}
            </p>
          </li>
          <li>
            <div>
              <h3>{lang ? "Analytics" : "分析"}</h3>
              <Toggle checked={analyticsLocal} setChecked={setAnalyticsLocal} />
            </div>
            <p>
              {lang
                ? "Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc."
                : "分析cookies用于了解访客如何与网站互动。这些cookies提供有关访客数量、跳出率、流量来源等指标的信息。"}
            </p>
          </li>
          <li>
            <div>
              <h3>{lang ? "Preference" : "偏好设置"}</h3>
              <Toggle
                checked={preferenceLocal}
                setChecked={setPreferenceLocal}
              />
            </div>
            <p>
              {lang
                ? "These cookies remember user preferences to enhance the user's browsing experience."
                : "这些cookies记住用户的偏好设置，以增强用户的浏览体验。"}
            </p>
          </li>
        </ul>
        <button
          className="save"
          onClick={() => {
            savePreferences(analyticsLocal, preferenceLocal);
          }}
        >
          {lang ? "Save Preferences" : "保存偏好设置"}
        </button>
      </div>
    </div>
  );
}

function Toggle(props: any) {
  return (
    <div className="toggle-custom">
      <div
        className={`${props.checked ? "yes" : "no"} layer`}
        onClick={() => {
          props.setChecked(props.checked ? 0 : 1);
        }}
      >
        <div className={`knob`}></div>
      </div>
    </div>
  );
}
