import React, { useContext } from "react";
import styles from "./Footer.module.css";
import { NavLink } from "react-router-dom";
import logo from "../../assets/media/company/nvig-logo-horizontal.png";
import { qrChat, qrFollow } from "../../assets/assetsExport";
import { getLang } from "../../features/app/appSlice";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";

const Footer: React.FC = () => {
  const lang = useSelector(getLang);
  return (
    <footer
      className={`${styles.footerContainer} text-white relative z-[100] bg-main-blue  p-8 text-left flex flex-col gap-20`}
    >
      <div className="flex flex-col md-lg:flex-row gap-10 justify-between text-sm text-white/80">
        <NavLink to="/">
          <img className="h-8" src={logo} alt="company logo" />
        </NavLink>
        <div className="flex flex-col sm:flex-row gap-12 justify-between">
          <div className="flex flex-col gap-6">
            <p className="text-main">
              {lang ? "OUR BUSINESSES" : "我们的业务"}
            </p>
            <ul className="flex flex-col gap-2 font-medium">
              <li>
                <NavLink to="/business/family-office">
                  {lang ? "Family Office" : "家族办公室"}
                </NavLink>
              </li>
              <li>
                <NavLink to="/business/real-estate">
                  {lang ? "Real Estate" : "房地产"}
                </NavLink>
              </li>
              <li>
                <NavLink to="/business/technology-investment">
                  {lang ? "Technology Investment" : "科技投资"}
                </NavLink>
              </li>
              <li>
                <NavLink to="/business/art-and-culture">
                  {lang ? "Art and Culture" : "艺术与文化"}
                </NavLink>
              </li>
              <li>
                <NavLink to="/business/private-club">
                  {lang ? "Private Club" : "私人俱乐部"}
                </NavLink>
              </li>
              <li>
                <NavLink to="/business/think-tank">
                  {lang ? "Think Tank" : "智库"}
                </NavLink>
              </li>
              <li>
                <NavLink to="/business/cambridge-foundation">
                  {lang ? "Cambridge Foundation" : "剑桥基金会"}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-6">
            <p className="text-main">{lang ? "ABOUT" : "关于"}</p>
            <ul className="flex flex-col gap-2 font-medium">
              <li>
                <HashLink to="/about#our-firm">
                  {lang ? "Our Firm" : "我们的公司"}
                </HashLink>
              </li>
              <li>
                <HashLink to="/about#purposes-values">
                  {lang ? "Our Purposes and Values" : "我们的宗旨和价值观"}
                </HashLink>
              </li>
              <li>
                <HashLink to="/about#compliance-legality">
                  {lang ? "Compliance and Legality" : "合规与合法性"}
                </HashLink>
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-main">{lang ? "CONNECT" : "联系"}</p>
            <div className="flex gap-2">
              <div>
                <img
                  className="w-16"
                  src={qrChat}
                  alt={lang ? "QR code for wechat" : "微信二维码"}
                />
                <p className=" text-xs text-center">Chat with Us</p>
              </div>
              <div>
                <img
                  className="w-16"
                  src={qrFollow}
                  alt={lang ? "QR code for wechat" : "微信二维码"}
                />
                <p className=" text-xs text-center">Follow Us</p>
              </div>
            </div>
            <div className="flex flex-col items-start sm:items-end gap-1 mt-6 text-sm">
              <p className="flex flex-col items-start sm:items-end mb-2">
                <span>16 Berkeley Street,</span>
                <span>Mayfair, London</span>
                <span>W1J 8DZ, UK</span>
              </p>
              <p>+44 (0)20 3741 8080</p>
              <p>
                <a href="mailto:info@newvgroup.com">info@newvgroup.com</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-white/20 pt-2  text-xs flex flex-col md:flex-row gap-4 md:gap-2 justify-between text-secondary-light">
        <div>
          <p className="opacity-70">
            &copy;{new Date().getFullYear()}{" "}
            {lang
              ? "New Vision Group All Rights Reserved"
              : "新愿景集团 版权所有"}
          </p>
          <p className="opacity-50 text-[10px] mt-1">
            New Vision International Group Limited
            <br />
            {lang
              ? "Registered in England and Wales, Company No. 13029696."
              : "新愿景国际集团有限公司，注册于英格兰及威尔士，公司编号为13029696。"}
          </p>
        </div>
        <div className="flex gap-4 opacity-70">
          <NavLink to="/policies/terms-and-conditions">
            {lang ? "Terms & Conditions" : "条款与条件"}
          </NavLink>
          <NavLink to="/policies/cookies-policy">
            {lang ? "Cookies Policy" : "Cookie政策"}
          </NavLink>
          <NavLink to="/policies/privacy-policy">
            {lang ? "Privacy Policy" : "隐私政策"}
          </NavLink>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
