import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLink } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import Publication from "../../components/Publication/Publication";
import ContactUsBanner from "../../components/ContactUsBanner/ContactUsBanner";
import {
  imgArtallBg,
  imgCambridgeBg,
  imgFoBg,
  imgReBg,
  imgTechBg,
  imgThinktankBg,
  imgVclubBg,
  videoHomeBg,
  videoHomeBgPoster,
} from "../../assets/assetsExport";
import { useSelector } from "react-redux";
import { getIsSmallScreen, getLang } from "../../features/app/appSlice";
import SlideUpAnimation from "../../components/SlideUpAnimation/SlideUpAnimation";

const HomePage: React.FC = () => {
  const lang = useSelector(getLang);
  const isSmallScreen = useSelector(getIsSmallScreen);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.6;
    }
  }, []);

  function HeroSection() {
    return (
      <div className="h-screen w-screen">
        <div>
          <video
            ref={videoRef}
            className="absolute top-0 left-0 w-screen h-screen object-cover"
            src={videoHomeBg}
            autoPlay
            loop
            muted
            playsInline
            poster={videoHomeBgPoster}
          />
          <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70"></div>
        </div>

        <div className="h-full w-full flex flex-col items-start p-4 mobile:p-10 sm:p-14 gap-4 sm:gap-8 text-left justify-end">
          <SlideUpAnimation>
            <div>
              <h1 className="text-white leading-tight font-poster text-5xl mobile:text-6xl opacity-80 transform scale-y-125 text-left">
                Global Vision
                <br />
                Local Expertise
              </h1>
              {!lang && (
                <p className="text-main opacity-80 text-2xl mt-16 sm:mt-12">
                  国际视野，本地专识
                </p>
              )}
            </div>
          </SlideUpAnimation>

          <div className="w-full relative z-[9999999] h-[0.5px] bg-white/60"></div>
          <p className="text-white opacity-80 sm:max-w-[650px] text-left leading-8">
            {lang
              ? "New Vision International Group (NVIG) is a London-based global asset allocation platform, providing comprehensive, customised solutions for businesses, high-net-worth individuals, and families seeking international growth and diversification."
              : "新愿景国际集团 New Vision International Group (NVIG)，总部位于英国伦敦，是全球资产配置专业平台，为有全球化综合发展需求的企业及高净值人士和家族提供一站式定制服务。"}
          </p>
        </div>
      </div>
    );
  }

  function WhatWeDoSection() {
    function Section({
      to,
      style,
      img,
      title,
      subtitle,
      description,
      isWide,
    }: {
      to: string;
      style?: any;
      img: any;
      title: string;
      subtitle: string;
      description: string;
      isWide?: boolean;
    }) {
      return (
        <NavLink to={to} target="_blank" style={style}>
          <SlideUpAnimation>
            <p className="font-semibold text-xl">{title}</p>
            <div className="w-full h-[0.5px] bg-gray-500 mt-1 mb-3"></div>
            <div className={`flex ${isWide ? "flex-row" : "flex-col"}`}>
              <img
                src={img}
                alt={subtitle + " image"}
                className={`${
                  isWide ? "w-1/2 sm:pr-2 lg:pr-4" : "w-full"
                } h-60 object-cover`}
              />
              <div className={isWide ? "w-1/2 sm:pl-2 lg:pl-4" : "w-full"}>
                <p className="font-text h-14 font-medium text-2xl mt-2 mb-4">
                  {subtitle}
                </p>
                <p>{description}</p>
              </div>
            </div>
          </SlideUpAnimation>
        </NavLink>
      );
    }

    const sections = [
      {
        img: imgFoBg,
        to: "/business/family-office",
        title: { en: "Family Office", cn: "家族办公室" },
        subtitle: {
          en: "Bespoke Wealth Management & Legacy Planning",
          cn: "定制化财富管理与传承规划",
        },
        description: {
          en: "We offer bespoke services for ultra-high-net-worth Chinese families, including global asset allocation, wealth inheritance, tax planning, trust structuring, and identity planning. We are committed to professional planning and stable growth, supporting the intergenerational transfer and global positioning of family wealth.",
          cn: "专为中国超高净值家族提供全球资产配置、财富传承、税务筹划、信托架构及身份规划等定制化服务。我们致力于专业规划与稳健增值，助力家族财富的跨代传承与全球布局。",
        },
      },
      {
        img: imgReBg,
        to: "/business/real-estate",
        title: { en: "Real Estate", cn: "房地产" },
        subtitle: {
          en: "Exclusive Property Investment & Advisory",
          cn: "独家房产投资与咨询",
        },
        description: {
          en: "Headquartered in London, our high-end real estate consultancy is ARLA Propertymark certified, specializing in premium real estate investments for high-net-worth individuals. We offer high-end property investment, acquisition, leasing, and management services, based on in-depth market analysis and strategic insights.",
          cn: "总部位于伦敦的高端地产咨询公司，受ARLA Propertymark认证，专注于高净值人士的优质地产投资。提供高端房产投资、收购、租赁和管理服务，基于深入的市场分析和战略见解。",
        },
      },
      {
        img: imgTechBg,
        to: "/business/technology-investment",
        title: { en: "Technology Investment", cn: "科技投资" },
        subtitle: {
          en: "UK Innovation & Global Capital",
          cn: "英国创新与全球资本",
        },
        description: {
          en: "A London-based technology investment consulting platform and business accelerator, linking UK university tech enterprises with the Chinese capital market.",
          cn: "伦敦科技投资咨询平台及企业加速器，连接英国大学科技企业与中国资本市场。",
        },
      },
      {
        img: imgArtallBg,
        to: "/business/art-and-culture",
        title: { en: "Art and Culture", cn: "艺术与文化" },
        subtitle: {
          en: "Elite Art Advisory & Cultural Exchange",
          cn: "精英艺术咨询与文化交流",
        },
        description: {
          en: "A London-based art and culture communication company, offering high-end art collection and investment advisory services, and promoting cultural exchanges between the East and the West.",
          cn: "伦敦艺术文化传播公司，提供高端艺术品收藏与投资咨询，促进东西方艺术文化交流。",
        },
      },
      {
        img: imgThinktankBg,
        to: "/business/think-tank",
        title: { en: "Think Tank", cn: "智库" },
        subtitle: {
          en: "Global Wealth Management Insights",
          cn: "全球财富管理洞察",
        },
        description: {
          en: "Facilitating wise decision-making through comprehensive research on global asset allocation, collaborating with renowned scholars and industry experts to provide practical insights into wealth management.",
          cn: "通过对全球资产配置的深入研究，促进明智决策，联合知名学者和行业专家提供实用的财富管理洞察。",
        },
      },
      {
        img: imgVclubBg,
        to: "/business/private-club",
        title: { en: "Private Club", cn: "私人俱乐部" },
        subtitle: {
          en: "Exclusive Private Lifestyle & Networking",
          cn: "独家私人生活方式与社交",
        },
        description: {
          en: "V Club is a high-end private club in London, offering a refined social and interest platform for like-minded high-net-worth individuals, fostering exclusive connections and sophisticated experiences within London's most prestigious circles.",
          cn: "V Club是伦敦高端私人俱乐部，为志同道合的高净值人士提供一个精致的社交和兴趣平台，在伦敦最负盛名的圈子中促进独家联系和精致体验。",
        },
      },
      {
        img: imgCambridgeBg,
        to: "/business/cambridge-foundation",
        title: { en: "Cambridge Foundation", cn: "剑桥基金会" },
        subtitle: {
          en: "Academic Excellence & Philanthropic Impact",
          cn: "学术卓越与慈善影响",
        },
        description: {
          en: "New Vision Cambridge Foundation is a corporate social responsibility initiative of the group. It supports academic excellence and philanthropy by promoting groundbreaking research, global collaboration, and transformative impact through projects led by Cambridge.",
          cn: "新愿景剑桥基金会 (New Vision CambridgeFoundation)，是集团企业社会责任感公益项目。支持学术卓越和慈善事业，通过剑桥主导的项目促进突破性研究、全球合作和变革性影响。",
        },
      },
    ];

    return (
      <div>
        <div>
          <p className="tag">{lang ? "WHAT WE DO" : "关于我们"}</p>
          <h2 className="header-border-t mt-2">
            {lang ? "Delivering for Our Client" : "为客户提供服务"}
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 sm:gap-x-4 lg:gap-x-8 gap-y-20 mt-12">
          {sections.map((i, index) => {
            return (
              <Section
                to={i.to}
                img={i.img}
                title={lang ? i.title.en : i.title.cn}
                subtitle={lang ? i.subtitle.en : i.subtitle.cn}
                description={lang ? i.description.en : i.description.cn}
                isWide={index === 0 && !isSmallScreen}
                style={index === 0 ? { gridColumn: "1 / -1" } : {}}
              />
            );
          })}
        </div>
      </div>
    );
  }

  function PublicationSection() {
    return <Publication withTitle={true} />;
  }

  return (
    <div>
      <HeroSection />
      <div className="content-container my-20 mobile:my-40">
        <WhatWeDoSection />
        <PublicationSection />
      </div>
      <ContactUsBanner />
    </div>
  );
};

export default HomePage;
