import React, { useContext } from "react";
import Publication from "../../components/Publication/Publication";
import SegmentPageTemplate from "./TemplatePage";
import TitleDescriptionItem from "../../components/TitleDescriptionItem/TitleDescriptionItem";
import Slogen from "../../components/Slogen/Slogen";
import ContactUsBanner from "../../components/ContactUsBanner/ContactUsBanner";
import { imgArtallBg, imgArtallLogos } from "../../assets/assetsExport";
import { useSelector } from "react-redux";
import { getIsMobile, getLang } from "../../features/app/appSlice";

export default function BusinessArtCulture() {
  const lang = useSelector(getLang);
  const isMobile = useSelector(getIsMobile);
  function OfferingsSection() {
    const offerings = [
      {
        title: {
          en: "Private & Corporate Art Collection",
          cn: "个人与企业购藏",
        },
        description: {
          en: "Expert guidance in acquiring, managing, and investing in high-value art collections.",
          cn: "提供专业指导，助力客户购藏、管理及投资高价值艺术品。",
        },
      },
      {
        title: {
          en: "Museum Management Consulting",
          cn: "博物馆管理咨询",
        },
        description: {
          en: "Strategic planning and curation support for museums and cultural institutions.",
          cn: "为博物馆及文化机构提供战略规划及展览策划支持。",
        },
      },
      {
        title: {
          en: "Art Curation & Exhibition Planning",
          cn: "艺术策划",
        },
        description: {
          en: "Tailored exhibitions that connect Eastern and Western art.",
          cn: "定制跨东西方文化的高端艺术展览。",
        },
      },
      {
        title: {
          en: "Expert Lectures & Guided Tours",
          cn: "专家讲座与导览",
        },
        description: {
          en: "Exclusive talks and tours by leading art experts.",
          cn: "由顶级艺术专家主讲的独家讲座与深度导览。",
        },
      },
      {
        title: {
          en: "Antique & Art Authentication",
          cn: "古董艺术品鉴定",
        },
        description: {
          en: "Provenance research and verification for collectors and institutions.",
          cn: "为收藏家与机构提供溯源研究与权威鉴定服务。",
        },
      },
    ];

    return (
      <div>
        <h2 className="header-border-t">
          {lang ? "Our Offerings" : "我们的服务"}
        </h2>
        <p className="header-description">
          {lang
            ? "Our mission is to bridge cultures and shape the future of art investment through expertise, innovation, and global collaboration."
            : "我们的使命是通过专业知识、创新和全球合作来连接文化并塑造艺术投资的未来。"}
        </p>
        <div className="content-grid">
          {offerings.map((i) => {
            return (
              <TitleDescriptionItem
                title={lang ? i.title.en : i.title.cn}
                description={lang ? i.description.en : i.description.cn}
                leftLine={true}
              />
            );
          })}
        </div>
      </div>
    );
  }

  function OurTeamSection() {
    return (
      <div className="header-border-t-flex mb-40">
        <h2>{lang ? "Our Team" : "我们的团队"}</h2>
        <div>
          <p>
            {lang
              ? "Our core team is composed of seasoned experts from various fields within the industry. Several of our experts are senior members of the century-old Oriental Ceramic Society (OCS) in the UK. We possess extensive experience in the commercial operations of Eastern and Western art, along with top-tier resources in the art industry, including world-class auction houses, galleries, art fairs, art brokers, and antique dealers."
              : "我们的核心团队由业内不同领域的资深专家组成，多位专家为百年历史的英国东方陶瓷协会The Oriental Ceramic Society（OCS）资深会员。我们拥有中西方艺术品商业运作的丰富经验，以及顶级的艺术行业资源，包括世界一流的拍卖行、画廊、艺术博览会、艺术品经纪人和古董商。"}
          </p>
          <div className="content-list">
            <p>{lang ? "BRINGING TOGETHER" : "汇聚"}</p>
            <ul>
              <li>{lang ? "Artists" : "艺术家"}</li>
              <li>{lang ? "Collectors & Investors" : "收藏家及投资者"}</li>
              <li>{lang ? "Top Auction Houses" : "顶级拍卖行"}</li>
              <li>{lang ? "Museums & Galleries" : "博物馆及画廊"}</li>
              <li>{lang ? "Art Scholars & Experts" : "艺术学者及专家"}</li>
              <li>{lang ? "Art & Equity Brokers" : "艺术及股权经纪人"}</li>
              <li>
                {lang
                  ? "High-Tech & Blockchain Innovations"
                  : "高科技及区块链创新"}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  function OurPartnershipsSection() {
    function Item({ title, list }: { title: string; list: string[] }) {
      return (
        <div>
          <p className="font-bold font-header text-2xl mb-4">{title}</p>
          <ul className="list-disc pl-5">
            {list.map((i) => {
              return <li className="font-light font-text">{i}</li>;
            })}
          </ul>
        </div>
      );
    }
    const partnerships = [
      {
        title: { en: "Auction Houses", cn: "拍卖行" },
        list: {
          en: ["Sotheby’s", "Christie’s", "Bonhams"],
          cn: ["苏富比拍卖行", "佳士得拍卖行", "邦瀚斯拍卖行"],
        },
      },
      {
        title: { en: "Museums", cn: "博物馆" },
        list: {
          en: ["Museum of London", "The Design Museum"],
          cn: ["伦敦博物馆", "伦敦设计博物馆"],
        },
      },

      {
        title: { en: "Art Galleries", cn: "艺术画廊" },
        list: {
          en: [
            "Artall Art Center",
            "Arthill Gallery",
            "Oxford Charmony (Tea Culture Center)",
          ],
          cn: ["艺术中心", "阿斯希尔画廊", "茶文化中心"],
        },
      },

      {
        title: { en: "Top Universities", cn: "顶尖大学" },
        list: {
          en: [
            "University of Cambridge",
            "University of Oxford",
            "University of London",
          ],
          cn: ["剑桥大学", "牛津大学", "伦敦大学"],
        },
      },

      {
        title: { en: "Art Schools", cn: "艺术学校" },
        list: {
          en: [
            "Royal College of Art",
            "Chelsea School of Arts",
            "Central Saint Martins",
          ],
          cn: ["皇家艺术学院", "切尔西艺术学院", "中央圣马丁艺术学院"],
        },
      },
      {
        title: { en: "Art Associations", cn: "艺术协会" },
        list: {
          en: ["Craft Council Gallery", "National Trust", "English Heritage"],
          cn: ["英国工艺艺术协会", "英国国家文化托管协会", "英国古迹保护协会"],
        },
      },
    ];

    return (
      <div>
        <div className="header-border-t-flex">
          <h2>{lang ? "Our Partnerships" : "我们的合作伙伴"}</h2>
          <div className="">
            <p className="">
              {lang
                ? "We provide customized services for art institutions, artists, collectors, and art investors."
                : "我们为艺术机构、艺术家、收藏家及艺术投资者提供定制服务。"}
            </p>
            <img
              className="mt-10 p-6 xs:p-20 bg-main-light-blue"
              src={imgArtallLogos}
              alt="logos of museums and art galleries for decoration"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 mobile:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-10 mt-20">
          {partnerships.map((i, index) => {
            return (
              <>
                <Item
                  title={lang ? i.title.en : i.title.cn}
                  list={lang ? i.list.en : i.list.cn}
                />
              </>
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <SegmentPageTemplate
      title={
        lang ? (
          "New Vision Artall"
        ) : (
          <span>新愿景{isMobile && <br />}艺术文化</span>
        )
      }
      description={
        lang
          ? "New Vision Artall is an art and cultural communication company based in London, offering authoritative consulting on high-end art collection and investment. We are dedicated to promoting cultural exchanges between the East and West, helping you easily enter the elite art circle. As a bridge for East-West art and cultural exchange, we directly connect with internationally renowned museums, art schools, and other institutions, organizing a series of online and offline activities such as art exhibitions and cultural forums. Our areas of focus include Chinese painting and calligraphy, antique porcelain, tea culture, and religious art."
          : "新愿景Artall（New Vision Artall）是一家位于英国伦敦的艺术文化传播公司，提供高端艺术品收藏与投资权威咨询，致力于促进东西方艺术文化交流，伴您轻松走进高端艺术圈。我们作为东西方艺术文化交流的桥梁，直接对接国际知名的博物馆、艺术院校等机构，举办艺术展览、文化论坛等一系列线上线下活动，涉及领域包括中国书画、古董瓷器、茶文化和宗教艺术等。"
      }
      img={{ src: imgArtallBg, alt: "gallery with paintings" }}
    >
      <div className="content-container">
        <OfferingsSection />
      </div>

      <Slogen
        tag={lang ? "YOUR GUIDE" : "我们是您"}
        slogen={
          <p>
            {lang
              ? "The Authority in Art Investment and Collection, Your Bridge to the UK Art Scene"
              : "投资收藏艺术品的权威，走进英国艺术圈的桥梁"}
          </p>
        }
      />
      <div className="content-container">
        <OurTeamSection />
        <OurPartnershipsSection />
        <Publication />
      </div>
      <ContactUsBanner />
    </SegmentPageTemplate>
  );
}
