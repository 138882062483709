export const imgArtallBg = require("./media/background/artall.jpg");
export const imgFoBg = require("./media/background/fo.jpg");
export const imgReBg = require("./media/background/re.jpg");
export const imgThinktankBg = require("./media/background/thinktank.jpg");
export const imgVclubBg = require("./media/background/vclub.jpg");
export const imgCambridgeBg = require("./media/background/cambridge.jpg");
export const imgTechBg = require("./media/background/tech.jpg");
export const videoHomeBg = require("./media/background/london.mp4");
export const videoHomeBgPoster = require("./media/background/london-poster.jpg");
export const imgAboutBg = require("./media/background/mayfair.jpg");
export const imgJudgeBusinessSchool = require("./media/background/cambridge-judge-business-school.jpg");
export const imgOurFirmBg = require("./media/background/team.jpg");
export const imgOffice = require("./media/background/office.jpg");
export const imgReMap = require("./media/background/reMap.jpg");
export const imgArtallLogos = require("./media/logo/artall-logos.png");
export const imgThinktankArticles = require("./media/background/thinkTankArticles.jpg");
export const imgVclubEvents = require("./media/background/vclubEvents.jpg");

// logos
export const logoArla = require("./media/logo/arla.jpg");
export const logoCmp = require("./media/logo/cmp.png");
export const logoDps = require("./media/logo/dps.png");
export const logoPrs = require("./media/logo/prs.png");
export const logoNrla = require("./media/logo/nrla.png");
export const logoOisc = require("./media/logo/oisc.png");
export const logoCiol = require("./media/logo/ciol.png");
export const logoCambridge = require("./media/logo/cambridge-logo.png");
export const logoCambridgeHorizontal = require("./media/logo/cjbs.png");
export const logoOxford = require("./media/logo/oxford-logo.png");
export const logoICL = require("./media/logo/icl-logo.png");
export const logoNVIG = require("./media/company/nvig-logo-horizontal.png");
export const logoNVIGDark = require("./media/company/nvig-logo-dark.png");
export const logoNVIGOnly = require("./media/company/nvig-logo.png");

export const qrChat = require("./media/company/qr-chat.png");
export const qrFollow = require("./media/company/qr-follow.png");
