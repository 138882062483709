import React, { useContext } from "react";
import Publication from "../../components/Publication/Publication";
import SegmentPageTemplate from "./TemplatePage";
import ContactUsBanner from "../../components/ContactUsBanner/ContactUsBanner";
import {
  imgThinktankArticles,
  imgThinktankBg,
} from "../../assets/assetsExport";
import { useSelector } from "react-redux";
import { getLang } from "../../features/app/appSlice";

export default function BusinessThinkTank() {
  const lang = useSelector(getLang);
  function OfferingsSection() {
    return (
      <div className="header-border-t-flex">
        <h2>{lang ? "Our Offerings" : "我们的服务"}</h2>
        <div>
          <p>
            {lang
              ? "We are committed to delving into international economics, finance and taxation, asset management, education, art, and culture in the context of globalization, providing cutting-edge information and integrated insights for high-net-worth individuals and families."
              : "我们致力于在全球化发展背景下，深耕国际经济、金融税务、资产管理、教育、艺术与文化等领域，为高净值人士与家庭提供最前沿信息与整合资讯。"}
          </p>
          <div className="content-list">
            <p>{lang ? "WE SPECIALISE IN" : "我们的专长"}</p>
            <ul>
              <li>{lang ? "International Economics" : "国际经济学"}</li>
              <li>{lang ? "Finance & Taxation" : "金融与税务"}</li>
              <li>{lang ? "Asset management" : "资产管理"}</li>
              <li>{lang ? "Technology Investment" : "技术投资"}</li>
              <li>{lang ? "Art and Culture" : "艺术与文化"}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  function OurPublicationsSection() {
    return (
      <div>
        <h2 className="header-border-t mt-36 mb-16">
          {lang ? "Our Publications" : "我们的出版物"}
        </h2>
        <img src={imgThinktankArticles} alt="" />
      </div>
    );
  }

  return (
    <SegmentPageTemplate
      title={
        lang ? (
          <span>
            N-Vision
            <br />
            Think Tank
          </span>
        ) : (
          "N维智库"
        )
      }
      description={
        lang
          ? "N-Vision Think Tank (NVTT), established in London, UK, adheres to the philosophy of 'bringing together global elites for shared sustainable development.' With a commitment to objectivity, depth, and practical application, NVTT focuses on enhancing industry knowledge of global asset allocation and wealth management best practices. The platform is composed of scholars from renowned universities worldwide and seasoned industry experts."
          : "N维智库（N-Vision Think Tank），成立于英国伦敦，秉承“汇聚全球精英，共享持续发展”的理念，保持“客观、深度、致用”的态度，专注于提高业界对全球资产配置和财富管理最佳实践的知识。NVTT平台是由中外知名高等学府学者与行业资深专家构成。"
      }
      img={{ src: imgThinktankBg, alt: "people discussing" }}
    >
      <div className="content-container">
        <OfferingsSection />
        <OurPublicationsSection />
        <Publication />
      </div>
      <ContactUsBanner />
    </SegmentPageTemplate>
  );
}
