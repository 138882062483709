import React from "react";
import "./PoliciesPage.scss";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLang } from "../../features/app/appSlice";

export function PoliciesCookiesPage() {
  const lang = useSelector(getLang);
  return (
    <>
      <Helmet>
        <title>{lang ? "Cookies Policy | NVIG" : "Cookies 政策 | NVIG"}</title>
        <meta
          name="description"
          content={
            lang
              ? "Learn about how NVIG uses cookies and similar technologies to improve your browsing experience on our website."
              : "了解 NVIG 如何使用 cookies 和类似技术来改善您在我们网站上的浏览体验。"
          }
        />
        <meta
          name="keywords"
          content={
            lang
              ? "cookies policy, NVIG cookies, website tracking, cookie preferences"
              : "cookies 政策, NVIG cookies, 网站跟踪, cookie 偏好"
          }
        />
      </Helmet>
      <div className={"policy-container policies-cookies-container"}>
        <h1 className="">{lang ? "Cookies Policy" : "Cookies 政策"}</h1>
        <p>
          {lang
            ? 'This Cookie Policy explains how NVIG ("we," "us," or "our") uses cookies and similar tracking technologies when you visit our website. By using our website, you consent to the use of cookies as described in this policy.'
            : "本 Cookies 政策解释了 NVIG（“我们”）如何在您访问我们的网站时使用 cookies 和类似的跟踪技术。使用我们的网站即表示您同意按照本政策中所述使用 cookies。"}
        </p>
        <ul>
          <li>
            <h2>{lang ? "1. What are Cookies?" : "1. 什么是 Cookies？"}</h2>
            {lang
              ? "Cookies are small text files that are stored on your device (computer, tablet, or mobile) when you visit a website. They enable the website to remember your actions and preferences (such as login details, language, font size, and other display preferences) over a certain period. Cookies serve various purposes, including making the website more user-friendly, analyzing website usage, and providing personalized content."
              : "Cookies 是您访问网站时存储在您设备（计算机、平板电脑或移动设备）上的小型文本文件。它们使网站能够在一段时间内记住您的操作和偏好（例如登录详细信息、语言、字体大小和其他显示偏好）。Cookies 用于多种目的，包括使网站更易于使用、分析网站使用情况以及提供个性化内容。"}
          </li>
          <li>
            <h2>
              {lang
                ? "2. Types of Cookies We Use"
                : "2. 我们使用的 Cookies 类型"}
            </h2>
            <ul>
              <li>
                <b>
                  {lang ? "2.1. Necessary Cookies:" : "2.1. 必要的 Cookies："}
                </b>{" "}
                {lang
                  ? "These cookies are essential for the operation of our website. They enable you to navigate the site, use its features, and access secure areas. Without these cookies, certain services may not be available to you."
                  : "这些 cookies 对于我们网站的运行至关重要。它们使您能够浏览网站、使用其功能并访问安全区域。没有这些 cookies，某些服务可能无法提供给您。"}
              </li>
              <li>
                <b>
                  {lang ? "2.2. Analytics Cookies:" : "2.2. 分析 Cookies："}
                </b>{" "}
                {lang
                  ? "We use analytics cookies to collect information about how visitors use our website, such as the pages visited, the time spent on each page, and the referring website. This information helps us understand and improve the performance of our website."
                  : "我们使用分析 cookies 来收集有关访问者如何使用我们网站的信息，例如访问的页面、在每个页面上花费的时间以及推荐网站。此信息帮助我们了解和改进我们网站的性能。"}
              </li>
              <li>
                <b>
                  {lang ? "2.3. Preference Cookies:" : "2.3. 偏好 Cookies："}
                </b>{" "}
                {lang
                  ? "Preference cookies are used to remember your preferences and settings on the website, such as language preferences and display settings. They enhance your browsing experience and make your interactions with the website more personalized."
                  : "偏好 cookies 用于记住您在网站上的偏好和设置，例如语言偏好和显示设置。它们增强了您的浏览体验，使您与网站的互动更加个性化。"}
              </li>
              <li>
                <b>
                  {lang ? "2.4. Marketing Cookies:" : "2.4. 营销 Cookies："}
                </b>{" "}
                {lang
                  ? "We may use marketing cookies to track your interactions with our website and display targeted advertisements based on your interests. These cookies may be set by us or by third-party advertising partners."
                  : "我们可能会使用营销 cookies 来跟踪您与我们网站的互动，并根据您的兴趣显示定向广告。这些 cookies 可能由我们或第三方广告合作伙伴设置。"}
              </li>
            </ul>
          </li>
          <li>
            <h2>{lang ? "3. Third-Party Cookies" : "3. 第三方 Cookies"}</h2>
            <p>
              {lang
                ? "We may also allow third-party service providers, such as analytics or advertising companies, to place cookies on our website. These third parties may collect information about your online activities over time and across different websites. We do not have control over these third-party cookies or their privacy practices. We recommend reviewing the respective privacy policies of these third parties."
                : "我们还可能允许第三方服务提供商（如分析或广告公司）在我们的网站上放置 cookies。这些第三方可能会收集有关您在线活动的信息，包括时间和跨不同网站的信息。我们无法控制这些第三方 cookies 或其隐私惯例。我们建议您查看这些第三方的隐私政策。"}
            </p>
          </li>
          <li>
            <h2>
              {lang ? "4. Cookie Consent and Control" : "4. Cookies 同意和控制"}
            </h2>
            <p>
              {lang
                ? "By using our website, you consent to the placement of cookies on your device as described in this Cookie Policy. You can control and manage cookies in various ways. Most web browsers allow you to accept or reject cookies or notify you when a cookie is being sent. However, please note that disabling or blocking certain cookies may impact the functionality and performance of the website."
                : "通过使用我们的网站，您同意按照本 Cookies 政策中所述在您的设备上放置 cookies。您可以通过多种方式控制和管理 cookies。大多数网络浏览器允许您接受或拒绝 cookies，或在发送 cookies 时通知您。但是，请注意，禁用或阻止某些 cookies 可能会影响网站的功能和性能。"}
            </p>
          </li>
          <li>
            <h2>
              {lang ? "5. Updates to the Cookie Policy" : "5. Cookies 政策更新"}
            </h2>
            <p>
              {lang
                ? "We may update this Cookie Policy from time to time to reflect changes in our practices or applicable laws. We encourage you to review this Cookie Policy periodically for any updates."
                : "我们可能会不时更新本 Cookies 政策，以反映我们的实践或适用法律的变化。我们鼓励您定期查看本 Cookies 政策以获取任何更新。"}
            </p>
          </li>
          <li>
            <h2>{lang ? "Contact Us" : "联系我们"}</h2>
            <p>
              {lang
                ? "If you have any questions or concerns about our use of cookies or this Cookie Policy, please contact us at"
                : "如果您对我们使用 cookies 或本 Cookies 政策有任何疑问或担忧，请通过以下方式联系我们"}
              <a href="mailto:info@newvgroup.com">info@newvgroup.com</a>.
            </p>
          </li>
        </ul>
      </div>
    </>
  );
}

export function PoliciesPrivacyPage() {
  const lang = useSelector(getLang);
  return (
    <>
      <Helmet>
        <title>{lang ? "Privacy Policy | NVIG" : "隐私政策 | NVIG"}</title>
        <meta
          name="description"
          content={
            lang
              ? "NVIG's Privacy Policy outlines how we collect, use, protect, and handle your personal information when you use our services."
              : "NVIG 的隐私政策概述了我们在您使用我们的服务时如何收集、使用、保护和处理您的个人信息。"
          }
        />
        <meta
          name="keywords"
          content={
            lang
              ? "privacy policy, NVIG privacy, data protection, personal information, privacy terms"
              : "隐私政策, NVIG 隐私, 数据保护, 个人信息, 隐私条款"
          }
        />
      </Helmet>
      <div className={"policy-container policies-privacy-container"}>
        <h1>{lang ? "Privacy Policy" : "隐私政策"}</h1>
        <p>
          {lang
            ? "At NVIG, we are committed to protecting the privacy and personal information of our website visitors, clients, and tenants. This Privacy Policy outlines how we collect, use, disclose, and protect the information we gather through our website and in the course of providing our services. By accessing or using our website or engaging with our services, you consent to the terms outlined in this Privacy Policy."
            : "在 NVIG，我们致力于保护我们网站访问者、客户和租户的隐私和个人信息。本隐私政策概述了我们如何通过我们的网站以及在提供服务的过程中收集、使用、披露和保护我们收集的信息。通过访问或使用我们的网站或参与我们的服务，您同意本隐私政策中概述的条款。"}
        </p>
        <ul>
          <li>
            <h2>{lang ? "Information Collection" : "信息收集"}</h2>
            <p>
              {lang
                ? "We may collect the following types of information:"
                : "我们可能会收集以下类型的信息："}
            </p>
            <ul>
              <li>
                <b>{lang ? "Personal Information:" : "个人信息："}</b>{" "}
                {lang
                  ? "When you voluntarily provide it to us, we may collect personal information such as your name, email address, phone number, and any other information you provide when contacting us or using our services."
                  : "当您自愿提供给我们时，我们可能会收集个人信息，例如您的姓名、电子邮件地址、电话号码以及您在联系我们或使用我们的服务时提供的任何其他信息。"}
              </li>
              <li>
                <b>{lang ? "Website Usage Information:" : "网站使用信息："}</b>{" "}
                {lang
                  ? "We may collect non-personal information about your interactions with our website, including IP addresses, browser details, device information, and website activity. This information helps us understand how visitors engage with our website and improve our services."
                  : "我们可能会收集有关您与我们网站互动的非个人信息，包括 IP 地址、浏览器详细信息、设备信息和网站活动。此信息帮助我们了解访问者如何与我们的网站互动并改进我们的服务。"}
              </li>
            </ul>
          </li>
          <li>
            <h2>{lang ? "Information Use" : "信息使用"}</h2>
            <p>
              {lang
                ? "We may use the collected information for the following purposes:"
                : "我们可能会将收集到的信息用于以下目的："}
            </p>
            <ul>
              <li>
                <b>{lang ? "Providing Services:" : "提供服务："}</b>{" "}
                {lang
                  ? "To respond to your inquiries, provide requested services, and communicate with you regarding property listings, appointments, or other relevant matters."
                  : "回应您的询问，提供所请求的服务，并与您沟通有关房产列表、预约或其他相关事项。"}
              </li>
              <li>
                <b>{lang ? "Marketing and Communication:" : "营销和沟通："}</b>{" "}
                {lang
                  ? "To send you marketing communications, newsletters, updates, or other promotional materials that may be of interest to you. You can opt-out of these communications at any time."
                  : "向您发送可能感兴趣的营销通讯、新闻通讯、更新或其他促销材料。您可以随时选择退出这些通讯。"}
              </li>
              <li>
                <b>{lang ? "Improving Our Services:" : "改进我们的服务："}</b>{" "}
                {lang
                  ? "To analyze website usage patterns, gather feedback, and enhance our website and services based on user preferences and needs."
                  : "分析网站使用模式，收集反馈，并根据用户偏好和需求增强我们的网站和服务。"}
              </li>
              <li>
                <b>{lang ? "Legal Compliance:" : "法律合规："}</b>{" "}
                {lang
                  ? "To comply with legal obligations, enforce our terms and conditions, protect our rights, and address any potential fraud or security issues."
                  : "遵守法律义务，执行我们的条款和条件，保护我们的权利，并解决任何潜在的欺诈或安全问题。"}
              </li>
            </ul>
          </li>
          <li>
            <h2>
              {lang ? "Information Sharing and Disclosure" : "信息共享和披露"}
            </h2>
            <p>
              {lang
                ? "We may share your information in the following circumstances:"
                : "在以下情况下，我们可能会共享您的信息："}
            </p>
            <ul>
              <li>
                <b>{lang ? "Service Providers:" : "服务提供商："}</b>{" "}
                {lang
                  ? "We may engage trusted third-party service providers who assist us in delivering our services. These service providers have access to the necessary information to perform their functions but are obligated to maintain the confidentiality and security of your information."
                  : "我们可能会聘请值得信赖的第三方服务提供商来协助我们提供服务。这些服务提供商可以访问执行其功能所需的信息，但有义务维护您的信息的机密性和安全性。"}
              </li>
              <li>
                <b>{lang ? "Legal Compliance:" : "法律合规："}</b>{" "}
                {lang
                  ? "We may share your information when required by law, court order, or legal process, or if we believe in good faith that such disclosure is necessary to protect our rights, safety, or the rights of others."
                  : "当法律、法院命令或法律程序要求时，或者如果我们真诚地认为此类披露对于保护我们的权利、安全或他人的权利是必要的，我们可能会共享您的信息。"}
              </li>
              <li>
                <b>{lang ? "Business Transfers:" : "业务转让："}</b>{" "}
                {lang
                  ? "In the event of a merger, acquisition, or transfer of assets, your information may be transferred as part of the transaction. We will take appropriate measures to ensure the continued protection of your information."
                  : "在合并、收购或资产转让的情况下，您的信息可能会作为交易的一部分进行转让。我们将采取适当措施确保您的信息继续受到保护。"}
              </li>
            </ul>
          </li>
          <li>
            <h2>{lang ? "Data Security" : "数据安全"}</h2>
            <p>
              {lang
                ? "We employ appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of data transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security."
                : "我们采用适当的安全措施来保护您的信息免受未经授权的访问、更改、披露或破坏。但是，请注意，通过互联网或电子存储传输数据的方法并非 100% 安全，我们无法保证绝对安全。"}
            </p>
          </li>
          <li>
            <h2>{lang ? "Data Retention" : "数据保留"}</h2>
            <p>
              {lang
                ? "We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law."
                : "我们会在必要的时间内保留您的个人信息，以实现本隐私政策中概述的目的，除非法律要求或允许更长的保留期。"}
            </p>
          </li>
          <li>
            <h2>{lang ? "Your Rights and Choices" : "您的权利和选择"}</h2>
            <p>
              {lang
                ? "You have the right to access, correct, update, or delete your personal information, subject to any legal requirements. You may also have the right to restrict or object to certain processing activities. To exercise these rights or make any inquiries regarding your personal information, please contact us using the information provided below."
                : "您有权访问、纠正、更新或删除您的个人信息，但须遵守任何法律要求。您也可能有权限制或反对某些处理活动。要行使这些权利或对您的个人信息进行任何查询，请使用以下提供的信息与我们联系。"}
            </p>
          </li>
          <li>
            <h2>{lang ? "Third-Party Links" : "第三方链接"}</h2>
            <p>
              {lang
                ? "Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of those websites. We encourage you to review the privacy policies of any third-party websites you visit."
                : "我们的网站可能包含指向第三方网站的链接。我们不对这些网站的隐私惯例或内容负责。我们鼓励您查看您访问的任何第三方网站的隐私政策。"}
            </p>
          </li>
          <li>
            <h2>{lang ? "Updates to Privacy Policy" : "隐私政策更新"}</h2>
            <p>
              {lang
                ? "We may update this Privacy Policy from time to time. We encourage you to review this Privacy Policy periodically to stay informed of any updates."
                : "我们可能会不时更新本隐私政策。我们鼓励您定期查看本隐私政策以了解任何更新。"}
            </p>
          </li>
          <li>
            <h2>{lang ? "Contact Us" : "联系我们"}</h2>
            <p>
              {lang
                ? "If you have any questions, concerns, or requests regarding this Privacy Policy or our privacy practices, please contact us:"
                : "如果您对本隐私政策或我们的隐私惯例有任何疑问、担忧或请求，请联系我们："}
            </p>
            <p className="mt-4">
              <b>NVIG</b>
            </p>
            <p>
              {lang
                ? "16 Berkeley Street, Mayfair, London"
                : "伦敦梅费尔伯克利街 16 号"}
            </p>
            <p>{lang ? "W1J 8DZ, United Kingdom" : "W1J 8DZ，英国"}</p>
            <p className="mt-4">
              <b>{lang ? "Email:" : "电子邮件："}</b>{" "}
              <a href="mailto:info@newvgroup.com">info@newvgroup.com</a>
            </p>
            <p>
              <b>{lang ? "Office:" : "办公室："}</b> +44 (0)20 3741 8080
            </p>

            <p className="mt-4">
              {lang
                ? "We are committed to addressing your inquiries and resolving any concerns to ensure the protection of your privacy."
                : "我们致力于解决您的疑问并解决任何问题，以确保保护您的隐私。"}
            </p>
          </li>
        </ul>
      </div>
    </>
  );
}

export function PoliciesTermsPage() {
  const lang = useSelector(getLang);
  return (
    <>
      <Helmet>
        <title>
          {lang ? "Terms and Conditions | NVIG" : "条款和条件 | NVIG"}
        </title>
        <meta
          name="description"
          content={
            lang
              ? "Read NVIG's Terms and Conditions to understand your rights and obligations when using our website and services."
              : "阅读 NVIG 的条款和条件，以了解您在使用我们的网站和服务时的权利和义务。"
          }
        />
        <meta
          name="keywords"
          content={
            lang
              ? "terms and conditions, NVIG terms, legal terms, user agreement"
              : "条款和条件, NVIG 条款, 法律条款, 用户协议"
          }
        />
      </Helmet>
      <div className={"policy-container policies-terms-container"}>
        <h1>{lang ? "Terms and Conditions" : "条款和条件"}</h1>
        <p>
          {lang
            ? 'These Terms and Conditions ("Terms") govern your access to and use of the NVIG website ("www.newvgroup.com"). By accessing or using the Website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please refrain from using the Website.'
            : "这些条款和条件（“条款”）管理您对 NVIG 网站（“www.newvgroup.com”）的访问和使用。通过访问或使用本网站，您同意受这些条款的约束。如果您不同意这些条款的任何部分，请不要使用本网站。"}
        </p>
        <ul>
          <li>
            <h2>{lang ? "1. Website Use" : "1. 网站使用"}</h2>
            <ul>
              <li>
                <b>{lang ? "1.1. Eligibility:" : "1.1. 资格："}</b>{" "}
                {lang
                  ? "You must be at least 18 years old and have the legal capacity to enter into a binding agreement to use the Website."
                  : "您必须年满 18 岁并具有法律能力才能签订具有约束力的协议以使用本网站。"}
              </li>
              <li>
                <b>{lang ? "1.2. Permitted Use:" : "1.2. 允许使用："}</b>{" "}
                {lang
                  ? "You agree to use the Website solely for lawful purposes and in accordance with these Terms. Any unauthorized use of the Website is strictly prohibited."
                  : "您同意仅将本网站用于合法目的并遵守这些条款。严禁未经授权使用本网站。"}
              </li>
              <li>
                <b>{lang ? "1.3. Account Creation:" : "1.3. 账户创建："}</b>{" "}
                {lang
                  ? "Some features or services on the Website may require you to create an account. You are responsible for providing accurate and up-to-date information during the account creation process and maintaining the confidentiality of your account credentials."
                  : "本网站上的某些功能或服务可能需要您创建帐户。您有责任在帐户创建过程中提供准确和最新的信息，并维护您的帐户凭据的机密性。"}
              </li>
              <li>
                <b>{lang ? "1.4. User Content:" : "1.4. 用户内容："}</b>{" "}
                {lang
                  ? "You may have the opportunity to submit or post content on the Website. By doing so, you grant NVIG a non-exclusive, worldwide, royalty-free, perpetual, and transferable license to use, reproduce, modify, adapt, distribute, and display the content for the purposes of operating and improving the Website."
                  : "您可能有机会在本网站提交或发布内容。通过这样做，您授予 NVIG 非排他性、全球性、免版税、永久性和可转让的许可，以用于操作和改进本网站的目的使用、复制、修改、改编、分发和展示内容。"}
              </li>
            </ul>
          </li>
          <li>
            <h2>{lang ? "2. Intellectual Property" : "2. 知识产权"}</h2>
            <ul>
              <li>
                <b>{lang ? "2.1. Ownership:" : "2.1. 所有权："}</b>{" "}
                {lang
                  ? "The Website and its contents, including but not limited to text, graphics, logos, images, and software, are the property of NVIG and are protected by intellectual property laws. You may not use, reproduce, modify, or distribute any content from the Website without prior written consent from NVIG."
                  : "本网站及其内容，包括但不限于文本、图形、徽标、图像和软件，均为 NVIG 的财产，并受知识产权法保护。未经 NVIG 事先书面同意，您不得使用、复制、修改或分发本网站的任何内容。"}
              </li>
              <li>
                <b>{lang ? "2.2. Trademarks:" : "2.2. 商标："}</b>{" "}
                {lang
                  ? "All trademarks, service marks, and logos displayed on the Website are the property of NVIG or their respective owners. You are prohibited from using any trademarks or logos without the prior written permission of the owner."
                  : "本网站上显示的所有商标、服务标志和徽标均为 NVIG 或其各自所有者的财产。未经所有者事先书面许可，禁止使用任何商标或徽标。"}
              </li>
            </ul>
          </li>
          <li>
            <h2>
              {lang
                ? "3. Disclaimer and Limitation of Liability"
                : "3. 免责声明和责任限制"}
            </h2>
            <ul>
              <li>
                <b>{lang ? "3.1. Website Content:" : "3.1. 网站内容："}</b>{" "}
                {lang
                  ? "The information provided on the Website is for general informational purposes only. While we strive to ensure the accuracy and timeliness of the content, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information."
                  : "本网站提供的信息仅供一般信息用途。虽然我们努力确保内容的准确性和及时性，但我们不对信息的完整性、准确性、可靠性、适用性或可用性作出任何明示或暗示的陈述或保证。"}
              </li>
              <li>
                <b>{lang ? "3.2. Third-Party Links:" : "3.2. 第三方链接："}</b>{" "}
                {lang
                  ? "The Website may contain links to third-party websites. These links are provided for your convenience, but we do not endorse or have control over the content, privacy policies, or practices of these websites. We are not responsible for any loss or damage incurred as a result of your use of any third-party website."
                  : "本网站可能包含指向第三方网站的链接。这些链接是为了您的方便而提供的，但我们不认可或控制这些网站的内容、隐私政策或做法。对于您使用任何第三方网站而导致的任何损失或损害，我们概不负责。"}
              </li>
              <li>
                <b>
                  {lang ? "3.3. Limitation of Liability:" : "3.3. 责任限制："}
                </b>{" "}
                {lang
                  ? "To the fullest extent permitted by law, NVIG and its affiliates, directors, employees, or agents shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with your use of the Website."
                  : "在法律允许的最大范围内，NVIG 及其附属公司、董事、员工或代理人不对因您使用本网站而产生的任何直接、间接、附带、特殊、后果性或惩罚性损害承担责任。"}
              </li>
            </ul>
          </li>
          <li>
            <h2>{lang ? "4. Privacy Policy" : "4. 隐私政策"}</h2>
            <p>
              {lang
                ? "Your use of the Website is subject to our"
                : "您对本网站的使用受我们的"}
              <NavLink to="/policies/privacy">
                {lang ? "Privacy Policy" : "隐私政策"}
              </NavLink>
              {lang
                ? ". By using the Website, you consent to the collection, use, and disclosure of your information as described in the Privacy Policy."
                : "。通过使用本网站，您同意按照隐私政策中所述收集、使用和披露您的信息。"}
            </p>
          </li>
          <li>
            <h2>{lang ? "5. Modifications to the Terms" : "5. 条款修改"}</h2>
            <p>
              {lang
                ? "NVIG reserves the right to modify or update these Terms at any time without prior notice. It is your responsibility to review these Terms periodically for any changes. Your continued use of the Website after any modifications to the Terms constitutes your acceptance of the revised Terms."
                : "NVIG 保留随时修改或更新这些条款的权利，恕不另行通知。您有责任定期查看这些条款以了解任何更改。您在条款修改后继续使用本网站即表示您接受修订后的条款。"}
            </p>
          </li>
          <li>
            <h2>
              {lang
                ? "6. Governing Law and Jurisdiction"
                : "6. 管辖法律和司法管辖权"}
            </h2>
            <p>
              {lang
                ? "These Terms shall be governed by and construed in accordance with the laws of England and Wales. Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in England and Wales."
                : "这些条款应受英格兰和威尔士法律管辖并根据其解释。因这些条款引起或与之相关的任何争议应受位于英格兰和威尔士的法院的专属管辖。"}
            </p>
          </li>
          <li>
            <h2>{lang ? "Contact Us" : "联系我们"}</h2>
            <p>
              {lang
                ? "If you have any questions, concerns, or feedback regarding these Terms, please contact us at"
                : "如果您对这些条款有任何疑问、担忧或反馈，请通过以下方式联系我们"}
              <a href="mailto:info@newvgroup.com">info@newvgroup.com</a>.
            </p>
          </li>
        </ul>
      </div>
    </>
  );
}
