// src/components/HtmlOverflowManager.tsx
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setIsMobile,
  setIsNarrowScreen,
  setIsSmallScreen,
} from "../../features/app/appSlice";
import { AppDispatch } from "../store";
import { debounce } from "../../util/debounce";

const ResizeManager: React.FC = React.memo(() => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const handleResize = debounce(() => {
      dispatch(setIsMobile(window.innerWidth < 500));
      dispatch(setIsSmallScreen(window.innerWidth < 640));
      dispatch(setIsNarrowScreen(window.innerWidth < 750));
    }, 300);

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return null; // This component doesn't render anything
});

export default ResizeManager;
