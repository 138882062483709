import React from "react";

export default function TitleDescriptionItem({
  title,
  description,
  leftLine,
  img,
}: {
  title: string;
  description: string;
  leftLine?: boolean;
  img?: any;
}) {
  return (
    <div className={`${leftLine ? "border-l border-black pl-3" : ""}`}>
      {img && <img className="h-16 mb-4" src={img.src} alt={img.alt} />}
      {title && (
        <p className="text-main text-2xl font-light font-text">{title}</p>
      )}
      {description && (
        <p className="mt-3 font-text font-light">{description}</p>
      )}
    </div>
  );
}
