import React, { useContext } from "react";
import Publication from "../../components/Publication/Publication";
import SegmentPageTemplate from "./TemplatePage";
import TitleDescriptionItem from "../../components/TitleDescriptionItem/TitleDescriptionItem";
import Slogen from "../../components/Slogen/Slogen";
import ContactUsBanner from "../../components/ContactUsBanner/ContactUsBanner";
import { imgCambridgeBg } from "../../assets/assetsExport";
import { useSelector } from "react-redux";
import {
  getIsMobile,
  getIsNarrowScreen,
  getLang,
} from "../../features/app/appSlice";

export default function BusinessCambridgeFoundation() {
  const lang = useSelector(getLang);
  const isNarrowScreen = useSelector(getIsNarrowScreen);
  const isMobile = useSelector(getIsMobile);

  function AboutSection() {
    return (
      <div className="header-border-t-flex mb-40">
        <h2>
          {lang ? "Commitment to" : "致力于"}
          <br />
          {lang ? "Cambridge’s Legacy" : "剑桥的遗产"}
        </h2>
        <p>
          {lang
            ? "Inspired by the principle of reciprocity, the New Vision Cambridge Foundation cherishes the university's long-standing history, rich academic environment, and extensive social connections, which have nurtured generations of entrepreneurial alumni and incubated numerous socially responsible global enterprises. The founding team of New Vision Group, all graduates of Cambridge University, is committed to using the company's success to support Cambridge students and innovative projects that align with the values of New Vision Group."
            : "“投我以桃，报之以李”，剑桥大学悠久的历史、浓厚的学术环境、广阔的社会联系，孕育了一代又一代具有企业家精神的优秀校友，孵化了一批批具有社会责任感的全球化企业。新愿景集团创始团队均毕业于剑桥大学，愿用企业的收获，支持剑桥大学莘莘学子，以及和新愿景集团价值观趋同的创新创业项目。"}
        </p>
      </div>
    );
  }

  function KeyInitiativesSection() {
    const offerings = [
      {
        title: { en: "Scholarships & Grants", cn: "奖学金与资助" },
        description: {
          en: "Providing financial support for outstanding students and groundbreaking research.",
          cn: "为优秀学生和前沿研究提供资金支持。",
        },
      },
      {
        title: { en: "Social Responsibility Programs", cn: "社会责任项目" },
        description: {
          en: "Encouraging initiatives that drive sustainable and ethical business practices.",
          cn: "鼓励推动可持续发展和道德商业实践的创新举措。",
        },
      },
      {
        title: { en: "Mentorship & Networking", cn: "导师计划与人脉拓展" },
        description: {
          en: "Connecting students and startups with industry leaders and experienced entrepreneurs.",
          cn: "连接学生与初创企业，对接行业领袖与资深企业家。",
        },
      },
      {
        title: { en: "Strategic Partnerships", cn: "战略合作" },
        description: {
          en: "Collaborating with institutions and organizations to create opportunities for talent development.",
          cn: "与机构及组织合作，创造人才发展机遇。",
        },
      },
      {
        title: {
          en: "Innovation & Entrepreneurship Support",
          cn: "创新与创业支持",
        },
        description: {
          en: "Funding and guidance for early-stage ventures that align with our values.",
          cn: "为早期创业项目提供资金及指导，助力企业成长。",
        },
      },
    ];

    return (
      <div className="mt-20">
        <h2 className="header-border-t">
          {lang ? "Our Key Initiatives" : "我们的关键举措"}
        </h2>
        <p className="header-description">
          {lang
            ? "We bridge academic excellence with real-world impact through:"
            : "我们将学术卓越与现实影响相结合，通过以下方式推动发展："}
        </p>
        <div className="content-grid">
          {offerings.map((i) => {
            return (
              <TitleDescriptionItem
                title={lang ? i.title.en : i.title.cn}
                description={lang ? i.description.en : i.description.cn}
                leftLine={true}
              />
            );
          })}
        </div>
      </div>
    );
  }

  return (
    <SegmentPageTemplate
      title={
        lang ? (
          "Cambridge Foundation"
        ) : (
          <span>剑桥{isMobile && <br />}基金会</span>
        )
      }
      description={
        lang
          ? "The New Vision Cambridge Foundation is a public welfare project reflecting the corporate social responsibility of the group."
          : "新愿景剑桥基金会（New Vision Cambridge Foundation），是集团企业社会责任感公益项目。"
      }
      img={{ src: imgCambridgeBg, alt: "cambridge" }}
    >
      <div className="content-container">
        <AboutSection />
      </div>
      <Slogen
        tag={lang ? "OUR PHILOSOPHY" : "我们的理念"}
        slogen={
          <p>
            {lang ? (
              <span>
                Supporting Innovation, {isNarrowScreen ? <br /> : null}{" "}
                Empowering Future Leaders.
              </span>
            ) : (
              "支持创新，赋能未来领袖。"
            )}
          </p>
        }
      />
      <div className="content-container">
        <KeyInitiativesSection />
        <Publication />
      </div>
      <ContactUsBanner />
    </SegmentPageTemplate>
  );
}
