import React, { Children } from "react";
import "./SlideUpAnimation.scss";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

export default function SlideUpAnimation(props: any) {
  const [ref, inView] = useInView({
    triggerOnce: props.triggerOnce,
    rootMargin: props.rootMargin || "0% 0px",
  });

  return (
    <motion.div
      className="w-auto h-auto"
      ref={ref}
      initial={{ opacity: 0, y: 100 }}
      animate={inView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 1, ease: "easeOut" }}
    >
      {props.children}
    </motion.div>
  );
}
