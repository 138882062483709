import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface appState {
  isWidthHideFilter: boolean;
  showFilter: boolean;
  showContactPopup: boolean;
  isMobile: boolean;
  isSmallScreen: boolean;
  isNarrowScreen: boolean;
  lang: boolean;
  cookieState: {
    analytics: number;
    preference: number;
    privacy: number;
  };
}

const initialState: appState = {
  isWidthHideFilter: false,
  showFilter: false,
  showContactPopup: false,
  isMobile: false,
  isSmallScreen: true,
  isNarrowScreen: true,
  lang: false,
  cookieState: {
    analytics: 0,
    preference: 0,
    privacy: 0,
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<boolean>) => {
      state.lang = action.payload;
    },
    setIsWidthHideFilter: (state, action: PayloadAction<boolean>) => {
      state.isWidthHideFilter = action.payload;
    },
    setShowFilter: (state, action: PayloadAction<boolean>) => {
      state.showFilter = action.payload;
    },
    setShowContactPopup: (state, action: PayloadAction<boolean>) => {
      state.showContactPopup = action.payload;
    },
    setIsSmallScreen: (state, action: PayloadAction<boolean>) => {
      state.isSmallScreen = action.payload;
    },
    setIsNarrowScreen: (state, action: PayloadAction<boolean>) => {
      state.isNarrowScreen = action.payload;
    },
    setIsMobile: (state, action: PayloadAction<boolean>) => {
      state.isMobile = action.payload;
    },
    setCookieState: (
      state,
      action: PayloadAction<{
        analytics: number;
        preference: number;
        privacy: number;
      }>
    ) => {
      state.cookieState = { ...state.cookieState, ...action.payload };
    },
  },
});

// SELECTORS
export const getIsWidthHideFilter = (state: any) => state.app.isWidthHideFilter;
export const getShowFilter = (state: any) => state.app.showFilter;
export const getShowContactPopup = (state: any) => state.app.showContactPopup;
export const getIsSmallScreen = (state: any) => state.app.isSmallScreen;
export const getIsNarrowScreen = (state: any) => state.app.isNarrowScreen;
export const getIsMobile = (state: any) => state.app.isMobile;
export const getLang = (state: any) => state.app.lang;
export const getCookieStateAnalytics = (state: any) =>
  state.app.cookieState.analytics;
export const getCookieStatePreference = (state: any) =>
  state.app.cookieState.preference;
export const getCookieStatePrivacy = (state: any) =>
  state.app.cookieState.privacy;

export const {
  setLang,
  setIsWidthHideFilter,
  setShowFilter,
  setShowContactPopup,
  setIsSmallScreen,
  setIsNarrowScreen,
  setIsMobile,
  setCookieState,
} = appSlice.actions;

export default appSlice.reducer;
